import React from 'react';
import {Text as ConstaText} from "@consta/uikit/Text";
import {AsTags} from "@consta/uikit/__internal__/src/utils/types/AsTags";
import {tr} from "date-fns/locale";

interface TextProps {
    content?: string;
    as?: AsTags;
    size?: 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl';
    view?: "link" | "primary" | "secondary" | "success" | "warning" | "alert" | "brand" | "ghost" | "linkMinor" | "system" | "normal" | "caution" | "critical" | undefined
    align?: 'left' | 'center' | 'right';
    weight?: 'regular' | 'medium' | 'bold';
    lineHeight?: "s" | "xs" | "m" | "l" | "2xs" | undefined;
    style?: boolean;
}

const TextAtom: React.FC<TextProps> = ({
                                           as = 'div',
                                           content,
                                           size = '2xl',
                                           view = 'primary',
                                           align = "center",
                                           weight = "bold",
                                           lineHeight = 'l',
                                           style = true
                                       }) => {
    return (
        <ConstaText as={as}
                    style={
                        style ? {
                            backgroundColor: '#0078d2',
                            color: 'white',
                            borderRadius: '5px',
                            marginBottom: '15px'
                        } : undefined
                    }
                    align={align}
                    lineHeight={lineHeight}
                    size={size}
                    spacing="m"
                    transform="uppercase"
                    view={view}
                    weight={weight}>
            {content}
        </ConstaText>
    );
};

export default TextAtom;
