import {fetchCreateChecklist} from "../service/fetch.endpoint";


export const createNewChecklist = async (props: any, savedChecklistData: any, newTemplateData: any, username: string | undefined) => {
    const saveData = {
        create_type: 'new',
        checklist_id: props.checklistID,
        current_template: props.currentTemplate,
        checklist_data: {
            username: username,
            template_id: savedChecklistData?.id || newTemplateData?.id,
            is_draft: true,
        },
    };
    await fetchCreateChecklist({saveData: saveData, isDraft: true});
}
