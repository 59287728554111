import React, {useEffect, useState} from "react";
import {Sidebar} from "@consta/uikit/Sidebar";
import {fetchDeleteChecklist} from "../../utils/service/fetch.endpoint";
import ButtonGroup from "../../molecules/new/buttonGroupMolecule.new";
import ChecklistView from "../../pages/new/checklist.form";
import {ViewModeType} from "../../types/new.type";

interface SidebarViewProps {
    isSidebarOpen: boolean;
    setIsSidebarOpen: (open: boolean) => void;
    checklistID: string;
    viewMode: ViewModeType;
    selectedTemplate?: any;
    setSelectedTemplate?: any;
    setSavedResult?: any;
}

const SidebarView: React.FC<SidebarViewProps> = (props) => {
    const [savePressed, setSavePressed] = useState<boolean>(false);
    const [saveDraftPressed, setSaveDraftPressed] = useState<boolean>(false);
    const [isSavedSuccess, setIsSavedSuccess] = useState<boolean>(false);

    const handleCloseSideBar = () => {
        props.viewMode === 'new' && props.setSelectedTemplate(null);
        props.setIsSidebarOpen(false);
    };

    const handleCancelButton = () => {
        props.viewMode === 'new' && fetchDeleteChecklist({checklistID: props.checklistID});
        handleCloseSideBar();
    };

    useEffect(() => {
        if (isSavedSuccess) {
            props.viewMode === 'new' && props.setSavedResult(isSavedSuccess);
            handleCloseSideBar();
        }
    }, [isSavedSuccess]);

    return (
        <Sidebar
            isOpen={props.isSidebarOpen}
            onClickOutside={handleCloseSideBar}
            onEsc={handleCloseSideBar}
            position='bottom'
            size='full'
        >
            <Sidebar.Content>
                {props.selectedTemplate && (
                    <ChecklistView
                        checklistID={props.checklistID}
                        newTemplateData={props.selectedTemplate}
                        viewMode={props.viewMode}
                        isSaveButtonPressed={savePressed}
                        isSaveDraftButtonPressed={saveDraftPressed}
                        isSavedSuccess={setIsSavedSuccess}
                        currentTemplate={props.selectedTemplate}
                        setIsSidebarOpen={props.setIsSidebarOpen}
                        setSavePressed={setSavePressed}
                    />
                )}
            </Sidebar.Content>
            <Sidebar.Actions>
                <ButtonGroup
                    onCancel={handleCancelButton}
                    onSave={() => setSavePressed(true)}
                    onDraft={() => setSaveDraftPressed(true)}
                    showSaveButton={props.viewMode !== 'view'}
                />
            </Sidebar.Actions>
        </Sidebar>
    );
};

export default SidebarView;
