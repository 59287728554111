import React, {MouseEventHandler, useEffect, useRef, useState} from 'react';
import {
    ArcElement, PieController, Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import {Line, getElementAtEvent, getElementsAtEvent, getDatasetAtEvent} from "react-chartjs-2";
import {chartDataProcess} from "./helpers/chartDataProcess";
import {ReportDataInterface} from "../page.report.bypass";
import {IconArrowUndone} from '@consta/icons/IconArrowUndone';
import {AnswerInterface} from "../../../pages/responsible/page.violante";
import {SaveMethodType} from "../../new/component/photo.collapse.cmp";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
let rootData: any[] = []

interface PointChartInterface {
    chartData: AnswerInterface[];
    setFilterData: (filterData: AnswerInterface[]) => void
    dataType: SaveMethodType;
}

const PointChart = (props: PointChartInterface) => {
    const [reportData, setReportData] = useState<any>(chartDataProcess(props.chartData, 'date', '*'));
    const chartRef: React.MutableRefObject<any> = useRef();
    const [isBackEnabled, setIsBackEnabled] = useState<boolean>(false)

    const data = {
        labels: reportData && Object.keys(reportData),
        datasets: [
            {
                label: 'Нарушений',
                data: Object.entries(reportData).map(([date, count]) => ({
                    x: date,
                    y: count,
                })),
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
                pointStyle: 'circle',
                pointRadius: 6,
                pointHoverRadius: 15
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: "top" as const
            },
            title: {
                display: true,
                text: "Динамика выявленных нарушений"
            },
            tooltip: {
                enabled: true // Отключаем подсказки (тултипы) для точек данных
            },
            datalabels: {
                display: false,
                color: 'black',
                formatter: (value: any) => `(${value.x}, ${value.y})`,
                padding: 6
            }
        }
    };

    const onClick = async (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
        rootData = props.chartData
        const activeElements = getElementAtEvent(chartRef.current, event);
        if (activeElements.length > 0) {
            const chartData = chartRef.current.data;
            const index = activeElements[0].index
            const label = chartData.labels[index];
            const value = chartData.datasets[0].data[index];

            const recipientCounts = chartDataProcess(chartData, 'date', label);
            setReportData(recipientCounts)

            const temp = chartData.filter(
                (item: AnswerInterface) => item.date === label
            )

            props.setFilterData(temp)
            setIsBackEnabled(true)

            // Обновить диаграмму
            // chartRef.current.update();
        }
    }

    const handlerIconBackClick = async (rootData: any) => {
        setReportData(chartDataProcess(rootData, 'date', '*'))
        props.setFilterData(rootData)
        setIsBackEnabled(false)
    }


    return (
        <div style={{position: 'relative'}}>
            <Line ref={chartRef} options={options} data={data} onClick={onClick}/>
            <div style={{position: 'absolute', top: '2%', right: '2%'}}>
                {isBackEnabled && <IconArrowUndone onClick={() => handlerIconBackClick(rootData)}/>}
            </div>
        </div>
    );
}
export default PointChart;