import React, {useState} from "react";
import {Collapse} from "@consta/uikit/Collapse";
import {Button} from "@consta/uikit/Button";
import {IconSettings} from "@consta/uikit/IconSettings";
import {TextField} from "@consta/uikit/TextField";

const CommentCollapse: React.FC<{
    index: number;
    valueComment: Array<any>;
    setValueComment: any;
}> = ({index, valueComment, setValueComment}) => {

    const [isOpen, setIsOpen] = useState<boolean>(valueComment[index]?.comment ? true : false);
    const [valueTextField, setValueTextField] = useState<string | null>(valueComment[index]?.comment);

    const handleChange = ({value}: { value: string | null }) => {
        const updatedValueComment = [...valueComment];
        updatedValueComment[index].comment = valueTextField;
        setValueComment(updatedValueComment);
        setValueTextField(value)
    }

    return (
        <Collapse
            label="Примечание"
            isOpen={isOpen}
            onClick={() => setIsOpen((prev: any) => !prev)}
            rightSide={
                <Button
                    iconLeft={IconSettings}
                    size="s"
                    view="ghost"
                    onClick={(e) => {
                        e.stopPropagation();
                        alert('Button Click');
                    }}
                />
            }
        >
            <TextField style={{width: '100%'}}
                       type="textarea"
                       rows={3}
                       onChange={handleChange}
                       value={valueTextField}
            />
        </Collapse>
    )
}
export default CommentCollapse;