import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, useLocation, Routes, Navigate} from "react-router-dom";
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {Theme, presetGpnDefault} from '@consta/uikit/Theme';
import "./page/css/mainPage.css";
import router from './routes';
import {configureAgGrid} from "./utils/service/config";
import HeaderLayout from "./header.layout";
import PrivateRoute from "./utils/wrapper/privateRoute";
import NewChecklistPage from "./pages/new/new.page";
import JournalPage from "./pages/journal/journal.page";
import PageReportBypass from "./page/report/page.report.bypass";
import PageReportKB from "./page/report/page.report.kb";
import PageViolante from "./pages/responsible/page.violante";
import PageChecklist from "./pages/responsible/page.checklist";
import HomePage from "./pages/home/home.page";
import {AuthProvider, useAuth} from "./utils/wrapper/authContext";

import LoginPage from "./pages/login/login";
import TokenRefreshWrapper from "./utils/wrapper/tokenRefresh";
import JournalDraftPage from "./pages/journal_draft/journalDraft.page";

// Настройка библиотек
configureAgGrid();

const queryClient = new QueryClient();

const App = () => {
    useEffect(() => {
        document.title = 'Чеклист';
    }, []);

    return (
        <Router>
            <AuthProvider>
                <TokenRefreshWrapper>
                    <QueryClientProvider client={queryClient}>
                        <Theme className="App" preset={presetGpnDefault}>
                            <ConditionalLayout>
                                <Routes>
                                    <Route path="/" element={<RootRedirect/>}/>
                                    <Route path="/new" element={<PrivateRoute><NewChecklistPage/></PrivateRoute>}/>
                                    <Route path="/journal" element={<PrivateRoute><JournalPage/></PrivateRoute>}/>
                                    <Route path="/draft-journal"
                                           element={<PrivateRoute><JournalDraftPage/></PrivateRoute>}/>
                                    <Route path="/total_info_bypass"
                                           element={<PrivateRoute><PageReportBypass/></PrivateRoute>}/>
                                    <Route path="/total_info_kb"
                                           element={<PrivateRoute><PageReportKB/></PrivateRoute>}/>
                                    <Route path="/violante" element={<PrivateRoute><PageViolante/></PrivateRoute>}/>
                                    <Route path="/checklist" element={<PrivateRoute><PageChecklist/></PrivateRoute>}/>
                                </Routes>
                            </ConditionalLayout>
                        </Theme>
                    </QueryClientProvider>
                </TokenRefreshWrapper>
            </AuthProvider>
        </Router>
    );
}


// условно рендерит Header и Footer
const ConditionalLayout: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const location = useLocation();
    const hideHeaderAndFooter = location.pathname === '/' || location.pathname.startsWith('/login');

    return (
        <>
            {!hideHeaderAndFooter && <HeaderLayout/>}
            <main>{children}</main>
        </>
    );
};

const RootRedirect: React.FC = () => {
    const {accessToken} = useAuth();
    return accessToken ? <Navigate to="/home" replace/> : <LoginPage/>;
};
export default App;
