import React, {useEffect} from 'react';
import {Grid} from "@consta/uikit/Grid";
import {BYPASS_TEMPLATE_ID} from "../../utils/service/config";
import RenderKarkas from "../../page/new/view/view.karkas.checklist";
import RenderBypassList from "../../page/new/view/view.bypass.checklist";
import {TemplateDataType} from "../../page/new/page.new";


interface QuestionGeneratorProps {
    currentTemplate: any;
    questionData: any;
    valueAnswer: any;
    setValueAnswer: (value: any) => void;
    newChecklistID: string;
    viewMode: string;
    valueMainInfo: any;
}

const QuestionGenerator: React.FC<QuestionGeneratorProps> = ({
                                                                 currentTemplate,
                                                                 questionData,
                                                                 valueAnswer,
                                                                 setValueAnswer,
                                                                 newChecklistID,
                                                                 viewMode,
                                                                 valueMainInfo
                                                             }) => {


    return (
        <Grid className="gridSlave" cols="12" gap="2xs" style={{width: '90%'}}>
            <>
                {/* Каркас */}
                {currentTemplate?.template_id !== BYPASS_TEMPLATE_ID &&
                    ((questionData?.question && valueAnswer) ?
                        RenderKarkas(questionData,
                            valueAnswer,
                            setValueAnswer) :
                        <p>Loading</p>)
                }
                {/* Обходной список */}
                {currentTemplate?.template_id === BYPASS_TEMPLATE_ID &&
                    ((questionData?.question && valueAnswer) ?
                        RenderBypassList(
                            questionData as TemplateDataType,
                            valueAnswer,
                            setValueAnswer,
                            newChecklistID,
                            viewMode,
                            valueMainInfo)
                        : <p>Loading</p>)
                }
            </>
        </Grid>
    );
};

export default QuestionGenerator;
