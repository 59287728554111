// constructor
export type ComboboxType = {
    id: string;
    label: string;
    model_name?: string;
    result_name: string;
    type: string;
    data?: {},
    group?: any,
    answer?: any
}

export interface MainInfoDictType {
    [key: string]: ComboboxType
}

export const MainInfoDict: MainInfoDictType = {
    struct: {
        id: 'c68cfa9e-e931-4452-8741-effac974a3cf',
        label: 'Структурное подразделение',
        model_name: 'StructuralSubdivision',
        result_name: 'struct',
        type: 'dict',
    },
    watch_place: {
        id: '2e6007ca-6fec-47b7-9c6d-44f0cc6e1cc3',
        label: 'Место осмотра',
        model_name: 'WatchPlace',
        result_name: 'watch_place',
        type: 'dict'
    },
    watch_org: {
        id: '6fb64aea-0b6e-4ea0-9de6-13db3dbdc5a5',
        label: 'Проверяемое предприятие',
        model_name: 'Org',
        result_name: 'watch_org',
        type: 'dict'
    },
    watch_ts_model: {
        id: '1063c6c9-7097-470b-b13f-e16f41ad5740',
        label: 'Марка, модель автомобиля',
        result_name: 'watch_ts_model',
        type: 'text'
    },
    watch_ts_gos_num: {
        id: '5f517387-186a-47ad-8eb1-38671a9014a4',
        label: 'Гос. рег. знак',
        result_name: 'watch_ts_gos_num',
        type: 'text'
    },
    watch_ts_year: {
        id: 'd6d81cef-e3ed-49e5-bc42-9eba4f55747e',
        label: 'Год выпуска',
        result_name: 'watch_ts_year',
        type: 'text'
    },
    watch_bush: {
        id: '888a491a-5774-4ecd-98de-518c11ffe457',
        label: 'КП',
        model_name: 'Bush',
        result_name: 'watch_bush',
        type: 'dict'
    }
}