import React, {useRef} from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Card} from "@consta/uikit/Card";
import {Text} from "@consta/uikit/Text";
import {Combobox} from "@consta/uikit/Combobox";
import {TextField} from "@consta/uikit/TextField";
import {Checkbox} from "@consta/uikit/Checkbox";
import {Button} from "@consta/uikit/Button";
import {TemplateDataType} from "../page.new";
import 'react-alice-carousel/lib/alice-carousel.css';
import PhotoCollapse from "../component/photo.collapse.cmp";

// @ts-ignore
import {v4 as uuidv4} from 'uuid';
import {fetchDeleteAnswer, fetchSaveNullAnswer} from "../../../utils/service/fetch.endpoint";
import {IconTrash} from "@consta/uikit/IconTrash";
import {BYPASS_TEMPLATE_QUESTION_ID} from "../../../utils/service/config";
import {ComboboxType} from "../../../types/new.type";


export type BypassType = {
    id: string,
    answer: string,
    number: string,
    recipient: ComboboxType,
    watch_object: ComboboxType,
    is_karkas: boolean,

}

export default function RenderBypassList(questionData: TemplateDataType,
                                         bypassData: any,
                                         setBypassData: any,
                                         checklistID: string,
                                         viewMode: string,
                                         valueMainInfo: any) {


    // bypass onChange
    const handleChangeBypass = (value: any, index: number, fieldName: string) => {
        if (bypassData) {
            const updatedValueBypassData: BypassType[] = [...bypassData];
            if (fieldName === 'recipient') {
                updatedValueBypassData[index].recipient = value;
            } else if (fieldName === 'answer') {
                updatedValueBypassData[index].answer = value;
            } else if (fieldName === 'watch_object') {
                updatedValueBypassData[index].watch_object = value;
            } else if (fieldName === 'number') {
                updatedValueBypassData[index].number = value;
            } else if (fieldName === 'isKarkas') {
                updatedValueBypassData[index].is_karkas = value;
            }
            setBypassData(updatedValueBypassData);
        }

    }
    const handleUpdatePhotos = (index: number, newPhoto: string[], newThumbnail: string[]) => {
        const updatedBypassData = [...bypassData];
        updatedBypassData[index].photo = newPhoto;
        updatedBypassData[index].thumbnail = newThumbnail;
        setBypassData(updatedBypassData);
    };
    const addItem = async () => {
        const answerID = uuidv4();
        try {
            await fetchSaveNullAnswer({draftAnswerID: answerID, checklistID: checklistID});
            const newItem = {
                answer_id: answerID,
                id: BYPASS_TEMPLATE_QUESTION_ID,
                number: null,
                answer: null,
                recipient: null,
                watch_object: null,
                is_karkas: false,
                comment: null,
            };
            const updatedBypassData = [...bypassData, newItem];
            setBypassData(updatedBypassData);
            handleScrollToBottom(); // Прокрутка вниз после добавления
        } catch (error) {
            console.error("Error in addItem:", error);
        }
    };

    const removeItem = (index: number) => {
        const answerID = bypassData[index].answer_id ? bypassData[index].answer_id : bypassData[index].id;
        fetchDeleteAnswer({answerID: answerID})
            .then(() => {
                const updatedData = bypassData.filter((_: any, i: number) => i !== index);
                setBypassData(updatedData);
            })
            .catch((error) => {
            });
    };


    const endOfListRef = useRef<HTMLDivElement | null>(null);

    const handleScrollToBottom = () => {
        if (endOfListRef.current) {
            endOfListRef.current.scrollIntoView({behavior: 'smooth'});
        }
    };


    return (
        <>
            {bypassData?.map((el: any, index: number) => (
                    <React.Fragment key={`bypass${index}`}>
                        <GridItem col={12}>
                            <Card className='card-view'>
                                <Grid cols={12}>
                                    <GridItem col={11}>
                                        <Text>{`#${index + 1}`}</Text>
                                    </GridItem>
                                    <GridItem col={1}>
                                        {viewMode !== 'view' &&
                                            <IconTrash onClick={() => removeItem(index)} view={'link'}/>}
                                    </GridItem>
                                    <GridItem col={6} style={{paddingRight: '10px'}}>
                                        <Combobox
                                            placeholder={'Обьект осмотра'}
                                            items={questionData?.bypass_info?.watch_object || []}
                                            groups={[]}
                                            size="m"
                                            view="default"
                                            form="default"
                                            labelPosition="top"
                                            value={bypassData[index]?.watch_object}
                                            status={!bypassData[index]?.watch_object ? 'alert' : 'success'}
                                            onChange={({value}) => handleChangeBypass(value, index, 'watch_object')}
                                            getItemKey={(item: any) => item.name}
                                            getItemLabel={(item: any) => item.name}
                                            getItemGroupKey={(item) => item.group}
                                            getGroupKey={(group: string) => group}
                                            getGroupLabel={(group: string) => group}
                                        />
                                    </GridItem>
                                    <GridItem col={4}>
                                        <TextField value={bypassData[index]?.number}
                                                   status={!bypassData[index]?.number ? 'alert' : 'success'}
                                                   onChange={({value}) => handleChangeBypass(value, index, 'number')}
                                                   placeholder="№"/>
                                        {/*<TextField style={{width: '100%'}} placeholder="№"/>*/}
                                    </GridItem>
                                    <GridItem col={2}>
                                        <div className="checkbox-container">
                                            <Text className="checkbox-text">КБ</Text>
                                            <Checkbox
                                                className='kb-checkbox'
                                                size="l"
                                                view="primary"
                                                checked={bypassData[index].is_karkas}
                                                align="top"
                                                disabled={viewMode !== 'view' ? false : true}
                                                onChange={({checked}) => handleChangeBypass(checked, index, 'isKarkas')}
                                            />
                                        </div>
                                    </GridItem>
                                    <GridItem col={12} style={{paddingTop: '10px'}}>

                                        <Combobox
                                            placeholder={'Ответственный'}
                                            items={questionData.bypass_info?.recipient || []}
                                            groups={[]}
                                            size="m"
                                            view="default"
                                            form="default"
                                            labelPosition="top"
                                            value={bypassData[index]?.recipient}
                                            status={!bypassData[index]?.recipient ? 'alert' : 'success'}
                                            onChange={({value}) => handleChangeBypass(value, index, 'recipient')}
                                            getItemKey={(item: any) => item.name}
                                            getItemLabel={(item: any) => item.name}
                                            getItemGroupKey={(item) => item.group}
                                            getGroupKey={(group: string) => group}
                                            getGroupLabel={(group: string) => group}
                                        />

                                    </GridItem>
                                    <GridItem col={12} style={{paddingTop: '10px'}}>
                                        <TextField value={bypassData[index]?.answer}
                                                   status={!bypassData[index]?.answer ? 'alert' : 'success'}
                                                   onChange={({value}) => handleChangeBypass(value, index, 'answer')}
                                                   style={{width: '100%'}}
                                                   type="textarea"
                                                   placeholder="Предписание"
                                                   labelPosition="top" rows={3}/>

                                    </GridItem>
                                    <GridItem col={12} style={{backgroundColor: 'lightgrey', marginTop: '10px'}}>

                                        <PhotoCollapse
                                            index={index}
                                            checklistID={checklistID}
                                            answerID={el.answer_id}  // Используем el.answer_id
                                            photo={el.photo}
                                            thumbnail={el.thumbnail}
                                            saveMethod={'new'}
                                            canDelete={viewMode !== 'view'}
                                            onUpdatePhotos={handleUpdatePhotos}
                                            viewMode={viewMode}
                                        />
                                    </GridItem>
                                </Grid>
                            </Card>
                        </GridItem>
                    </React.Fragment>
                )
            )}
            {viewMode !== 'view' &&
                <React.Fragment>
                    {bypassData.length === 0 &&
                        <GridItem col={12}>
                            <Text view="system">Заполните основную информацию и добавьте обьект осмотра</Text>
                        </GridItem>}

                    <GridItem col={'9'}>
                        <Button view='secondary' label="+ обьект" onClick={addItem}/>
                    </GridItem>
                </React.Fragment>
            }
        </>
    )
}

