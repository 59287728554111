import {MainInfoDict} from "../service/config";

export const getMainInfoDataFromSavedData = (keyList: any, mainInfo: any) => {
    return keyList
        .map((key: any) => ({
            ...MainInfoDict[key],
            data: mainInfo[key]?.data || null,
            answer: mainInfo[key]?.answer || null,
            group: mainInfo[key]?.group || null,
        }))
        .filter((item: any) => item.answer !== null);
}