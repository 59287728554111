import {axiosInstance} from "./axiosConfig";
import {
    API_DICT_QUESTION_GET,
    API_REGISTRY_CHECKLIST,
    API_REGISTRY_DELETE_DECIDED_PHOTO,
    API_REGISTRY_REPORT_DATA,
    API_REGISTRY_RESPONSIBLE_DATA,
    API_REGISTRY_SAVE_DRAFT_ANSWER,
    getAnswerAssignURL,
    getAnswerDecidedURL,
    getAnswerDeleteURL,
    getChecklistDeleteDraftURL, getPhotoDeleteURL,
    getSavedChecklist,
    getUpdateChecklistURL
} from './api.endpoint';
import {MainInfoDict} from '../../core.component/mainInfo.constructor';
import {ReportDataInterface} from "../../page/report/page.report.bypass";
import {BYPASS_TEMPLATE_QUESTION_ID} from "./config";
import {JournalTemplateProps} from "../../templates/journal.template";


export type ChecklistTemplateType = 'karkas' | 'bypass';

// Helper functions for requests
const fetchGet = async (url: string, params?: any) => {
    try {
        const response = await axiosInstance.get(url, {params});
        return response.data;
    } catch (error) {
        console.error(`GET ${url} failed:`, error);
        throw error;
    }
};

const fetchPost = async (url: string, data?: any) => {
    try {
        const response = await axiosInstance.post(url, data);
        return response;
    } catch (error) {
        console.error(`POST ${url} failed:`, error);
        throw error;
    }
};

const fetchPut = async (url: string, data?: any) => {
    try {
        return await axiosInstance.put(url, data);
    } catch (error) {
        console.error(`PUT ${url} failed:`, error);
        throw error;
    }
};

const fetchDelete = async (url: string, params?: any) => {
    try {
        return await axiosInstance.delete(url, {params});
    } catch (error) {
        console.error(`DELETE ${url} failed:`, error);
        throw error;
    }
};

// Checklist related functions
export const fetchCreateChecklist = async ({saveData, isDraft}: { saveData: any; isDraft: boolean }) => {
    saveData.checklist_data.is_draft = isDraft;
    return await fetchPost(API_REGISTRY_CHECKLIST, saveData);
};

export const fetchUpdateChecklist = async ({saveData, isDraft}: { saveData: any; isDraft: boolean }) => {
    saveData.checklist_data.is_draft = isDraft;
    if (!saveData.checklist_id || !saveData.current_template || !saveData.checklist_data) {
        console.error('Некорректные данные для сохранения');
        return;
    }
    return await fetchPut(getUpdateChecklistURL(saveData.checklist_id), saveData);
};

export const fetchNewChecklist = async ({templateId}: { templateId?: string }) => {
    return await fetchGet(API_DICT_QUESTION_GET, {
        template_id: templateId,
        main_info_dict: JSON.stringify(MainInfoDict),
    });
};

export const fetchSavedChecklist = async ({checklistId}: { checklistId: string }) => {
    return await fetchGet(getSavedChecklist(checklistId), {
        main_info_dict: JSON.stringify(MainInfoDict),
    });
};

export const fetchChecklistData = async (userRole: string, {fetchType}: JournalTemplateProps) => {
    return await fetchGet(API_REGISTRY_CHECKLIST, {
        user_role: userRole,
        ...(fetchType && {fetch_type: fetchType}), // Добавляем fetch_type только если fetchType существует
    });
};

export const fetchDeleteChecklist = async ({checklistID}: { checklistID: string }) => {
    return await fetchDelete(getChecklistDeleteDraftURL(checklistID));
};

// Answer related functions
export const fetchReportData = async ({checklistTemplate}: { checklistTemplate: ChecklistTemplateType }) => {
    return await fetchGet(API_REGISTRY_REPORT_DATA, {
        main_info_dict: JSON.stringify(MainInfoDict),
        checklist_template_type: checklistTemplate,
    }) as ReportDataInterface;
};

export const fetchResponsibleData = async () => {
    return await fetchGet(API_REGISTRY_RESPONSIBLE_DATA);
};

export const fetchSaveNullAnswer = async ({draftAnswerID, checklistID}: {
    draftAnswerID: string;
    checklistID: string
}) => {
    return await fetchPost(API_REGISTRY_SAVE_DRAFT_ANSWER, {
        answer_id: draftAnswerID,
        checklist_id: checklistID,
        question_id: BYPASS_TEMPLATE_QUESTION_ID,
    });
};

export const fetchDeleteAnswer = async ({answerID}: { answerID: string }) => {
    return await fetchDelete(getAnswerDeleteURL(answerID));
};

export const fetchAnswerAssign = async ({answerID}: { answerID: string }) => {
    return await fetchPut(getAnswerAssignURL(answerID));
};

export const fetchUpdateDecidedAnswer = async ({answerID, decideComment, returnComment,}:
                                                   {
                                                       answerID: string;
                                                       decideComment: string | null;
                                                       returnComment: string | null;
                                                   }) => {
    if (!decideComment && !returnComment) {
        return;
    }

    return await fetchPut(getAnswerDecidedURL(answerID), {
        params: {
            decide_comment: decideComment,
            return_comment: returnComment,
        },
    });
};

export const fetchDeleteDecidedPhoto = async ({answerID}: { answerID: string }) => {
    return await fetchDelete(API_REGISTRY_DELETE_DECIDED_PHOTO, {answer_id: answerID});
};

export const fetchDeletePhoto = async ({photoID}: { photoID: string }) => {
    return await fetchDelete(getPhotoDeleteURL(photoID));
};
