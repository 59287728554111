import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {AgGridReact} from '@ag-grid-community/react';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css";
import "./css/grid.report.css";
import {CellDoubleClickedEvent} from "@ag-grid-community/core";
import {CellClickedEvent} from '@ag-grid-community/core';
import {Sidebar} from "@consta/uikit/Sidebar";
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Button} from "@consta/uikit/Button";
import {ChecklistTemplateType, fetchUpdateDecidedAnswer} from "../../../utils/service/fetch.endpoint";
import {AnswerInterface, viewTypeType} from "../../../pages/responsible/page.violante";
import {
    colDef,
    columnDefsInspectorBypass, columnDefsInspectorKB,
    getRowStyle,
    gridContext,
    rowStyle,
    statusPanel
} from "./helpers/grid.report.settings";
import AnswerDetailKB from "./answer.detail.kb";
import AnswerDetailBypass from "./answer.detail.bypass";

interface GridReportInterface {
    filterData: any
    setShowSnackBar?: any
    viewType: viewTypeType
    checklistTemplateType: ChecklistTemplateType
}

const GridReport = (props: GridReportInterface) => {
    const gridRef = useRef<any>();
    const [rowData, setRowData] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [isDecided, setIsDecided] = useState(false);
    const [showSidebarAnswerDetail, setShowSidebarAnswerDetail] = useState(false);
    const [answerData, setAnswerData] = useState<AnswerInterface>();
    const [toSaveDecideComment, setToSaveDecideComment] = useState<string | null>(null)
    const [toSaveReturnComment, setToSaveReturnComment] = useState<string | null>(null)
    const [divHeight, setDivHeight] = useState(0);

    // контекстное меню
    const getContextMenuItems = useCallback((params: any) => {
        return gridContext()
    }, []);

    const customEngLocale = {
        rowGroupColumnsEmptyMessage: 'Перетащите сюда, чтобы сгруппировать значения',
        pinColumn: 'Закрепить столбец',
        pinLeft: 'Закрепить слева',
        pinRight: 'Закрепить справа',
        noPin: 'Не закреплять',
        autosizeThisColumn: 'Автонастройка ширины',
        autosizeAllColumns: 'Автонастройка ширины всех колонок',
        groupBy: 'Группировать по',
        resetColumns: 'Сбросить настройки',
        ariaSearch: 'Поиск...',
        columnGroup: 'Группировка'
    };

    const gridOptions = {
        localeText: customEngLocale
    };

    const onCellDoubleClicked = (params: CellDoubleClickedEvent) => {
        console.log(params.colDef.field)
        console.log(params.node)
    }

    const getRowId = useMemo(() => {
        return (params: any) => {
            return `${params.data.checklist_id}-${params.data.answer_id} `
        };
    }, []);

    const defaultColDef = useMemo(() => {
        return colDef
    }, []);

    // const fetchData = async () => {
    //     try {
    //         const fetchData = await fetchReportData({
    //             username: localStorage.username,
    //             checklistTemplate: 'bypass'
    //         })
    //         setIsLoading(false);
    //         return fetchData;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const onGridReady = async () => {
        // if responsible checklist open
        if (props.checklistTemplateType === 'karkas' && props.viewType === 'responsible_cabinet') {
            setRowData(props.filterData)
            return
        }
        if (props.checklistTemplateType === 'karkas') {
            // convert filterData to checklist group
            const resultData: { checklist_id: string; checklist_name: string; count: number; date: string }[] = [];

            for (const answer of props.filterData) {
                const {checklist_id, checklist, date} = answer;
                const existingEntry = resultData.find((entry) => entry.checklist_id === checklist_id);

                if (existingEntry) {
                    existingEntry.count++;
                } else {
                    resultData.push({checklist_id, checklist_name: checklist, count: 1, date: date});
                }
            }
            setRowData(resultData)
        } else {
            setRowData(props.filterData)
        }

    };

    const onCellClicked = (event: CellClickedEvent) => {
        if (event.event?.type === 'click') {
            setShowSidebarAnswerDetail(true)
            setAnswerData(event.data)
        }
    };

    const statusBar = useMemo(() => {
        return statusPanel
    }, []);


    const handleCloseSideBar = () => {
        setShowSidebarAnswerDetail(false)
        setIsDecided(false)
    }

    const handleSaveAndCloseSideBar = async (decideComment: string | null, returnComment: string | null) => {
        answerData &&
        await fetchUpdateDecidedAnswer({
            answerID: answerData.answer_id,
            decideComment: decideComment,
            returnComment: returnComment
        })

        setShowSidebarAnswerDetail(false)
        setIsDecided(false)
        props.setShowSnackBar(true)
    }


    useEffect(() => {
        onGridReady()
    }, [props.filterData]);


    // useEffect(() => {
    //     if (props.viewType === 'report') {
    //         const fetchDataAsync = async () => {
    //             const data = await fetchData();
    //             setRowData(data?.new);
    //         };
    //         fetchDataAsync();
    //     }
    // }, []);

    // resize grid to bottom
    useEffect(() => {
        const handleResize = () => {
            const viewportHeight = window.innerHeight;
            // @ts-ignore
            const elementOffsetTop = document.querySelector('.ag-theme-alpine').offsetTop;
            const calculatedHeight = viewportHeight - elementOffsetTop;
            setDivHeight(calculatedHeight);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <React.Fragment>
            <div className='ag-theme-alpine grid-report'>
                {rowData && isLoading ? "LOADING" :
                    <AgGridReact
                        gridOptions={gridOptions}
                        rowStyle={rowStyle}
                        getRowStyle={getRowStyle}
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={props.checklistTemplateType === 'karkas' ? columnDefsInspectorKB : columnDefsInspectorBypass}
                        animateRows={true}
                        getRowId={getRowId}
                        defaultColDef={defaultColDef}
                        onGridReady={onGridReady}
                        statusBar={statusBar}
                        onCellDoubleClicked={onCellDoubleClicked}
                        allowContextMenuWithControlKey={true}
                        getContextMenuItems={getContextMenuItems}
                        onCellClicked={onCellClicked}
                        rowGroupPanelShow={'always'}
                    />
                }
            </div>
            <Sidebar
                isOpen={showSidebarAnswerDetail}
                onClickOutside={() => handleCloseSideBar()}
                onEsc={() => handleCloseSideBar()}
                position='bottom'
                size={isDecided ? 'full' : '2/3'}
                className="sidebar">
                <Sidebar.Content>
                    {answerData && props.checklistTemplateType === 'bypass' &&
                        <AnswerDetailBypass answerData={answerData}
                                            setShowSidebarAnswerDetail={setShowSidebarAnswerDetail}
                                            setShowSnackBar={props.setShowSnackBar}
                                            viewType={props.viewType}
                                            isDecided={isDecided}
                                            setIsDecided={setIsDecided}
                                            setToSaveDecidedComment={setToSaveDecideComment}
                                            setToSaveReturnComment={setToSaveReturnComment}/>}
                    {answerData && props.checklistTemplateType === 'karkas' &&
                        <AnswerDetailKB answerData={answerData}
                                        setShowSidebarAnswerDetail={setShowSidebarAnswerDetail}
                                        setShowSnackBar={props.setShowSnackBar}
                                        viewType={props.viewType}
                                        isDecided={isDecided}
                                        setIsDecided={setIsDecided}
                                        setToSaveDecidedComment={setToSaveDecideComment}
                                        setToSaveReturnComment={setToSaveReturnComment}/>}
                </Sidebar.Content>
                <Sidebar.Actions>
                    <Grid cols={1}>
                        <GridItem col={1}>
                            {isDecided ?
                                <Button
                                    size="m"
                                    view="ghost"
                                    label="Сохранить"
                                    width="full"
                                    onClick={() => handleSaveAndCloseSideBar(toSaveDecideComment, toSaveReturnComment)}/> :
                                <Button
                                    size="m"
                                    view="ghost"
                                    label="Закрыть"
                                    width="full"
                                    onClick={() => handleCloseSideBar()}/>
                            }
                        </GridItem>
                    </Grid>
                </Sidebar.Actions>
            </Sidebar>
        </React.Fragment>
    )
        ;
};

export default GridReport;