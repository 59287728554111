export const CheckedNullCountAnswer = (currentChecklistData: any) => {
    let nullCount = 0;
    for (let i = 0; i < currentChecklistData.length; i++) {
        const cur = currentChecklistData[i];
        const {comment, ...checkedNullObject} = cur;
        const values = Object.values(checkedNullObject);
        const cnt = values.filter((val: any) => val === null).length;
        if (cnt > 0) {
            nullCount += cnt;
        }
    }
    return nullCount
}