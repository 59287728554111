import React from "react";
import {GridItem} from "@consta/uikit/Grid";
import {Text} from "@consta/uikit/Text";
import {RadioGroup} from "@consta/uikit/RadioGroup";
import CommentCollapse from "../component/comment.collapse.cmp";

export type KarkasType = {
    id: string | null;
    category: string;
    question: string;
    points: number;
    measures: string;
    template: string;
    chapter: string;
    answer: string | null;
    comment?: string;
}

export default function RenderKarkas(questionData: any, valueAnswer: any, setValueAnswer: any) {
    const checker = ['ДА', 'НЕТ']
    if (questionData && questionData.question && valueAnswer) {
        return questionData.question.map((el: any, index: number) => (
            <React.Fragment key={`question-${el?.id}`}>
                {/*Заголовок раздела чек листа*/}
                {
                    index === 0 ?
                        <GridItem col={12} style={{backgroundColor: 'lightgrey'}}>
                            <Text lineHeight="2xs" view='normal' weight='bold'
                                  size='xl'>{el?.chapter}</Text>
                        </GridItem> :
                        questionData.question && questionData.question[index - 1]?.chapter !== el?.chapter &&
                        <GridItem col={12} style={{backgroundColor: 'lightgrey'}}>
                            <Text lineHeight="2xs" view='normal' weight='bold'
                                  size='xl'>{el?.chapter}</Text>
                        </GridItem>
                }
                {/*Вопросы*/}
                <GridItem col={2} style={{backgroundColor: 'lightgrey'}}>
                    <Text lineHeight="2xs" weight='bold' align='center'>{el?.number}</Text>
                </GridItem>
                <GridItem col={3} style={{backgroundColor: 'lightgrey'}}>
                    <Text size='xs' weight='bold' align='center'>{el?.category}</Text>
                </GridItem>
                <GridItem col={3} style={{backgroundColor: 'lightgrey'}}>
                    <Text lineHeight="2xs" align='center'>Баллы-{el?.points}</Text>
                </GridItem>
                <GridItem col={4}
                          style={{backgroundColor: valueAnswer[index]?.answer && (valueAnswer[index]?.answer === 'ДА' ? 'rgba(30,162,77,0.64)' : 'rgba(255,0,0,0.64)')}}>
                    <RadioGroup
                        value={valueAnswer[index]?.answer}
                        items={checker}
                        getItemLabel={(item: string) => item}
                        onChange={({value}) => {
                            const updatedValueAnswer = [...valueAnswer];
                            updatedValueAnswer[index].answer = value;
                            setValueAnswer(updatedValueAnswer);
                        }}
                        direction="row"
                        align='center'/>
                </GridItem>
                <GridItem col={12}>
                    <Text lineHeight="2xs" align="left" view="primary">{el?.question}</Text>
                </GridItem>
                <GridItem col={4} style={{backgroundColor: 'lightgrey'}}>
                    <Text weight='bold' lineHeight="2xs" align="left">Меры реагирования</Text>
                </GridItem>
                <GridItem col={8}>
                    <Text lineHeight="2xs" align="left">{el?.measures}</Text>
                </GridItem>
                <GridItem col={12} style={{backgroundColor: 'lightgrey'}}>
                    <CommentCollapse index={index}
                                     valueComment={valueAnswer}
                                     setValueComment={setValueAnswer}/>
                </GridItem>
                <GridItem col={12} style={{height: '15px'}}></GridItem>
            </React.Fragment>
        ))
    }
}