import React, {useState} from 'react';

import '../styles/login.css'
import LoginForm from "../organisms/login/loginForm.organism";

interface LoginTemplateProps {
    onSubmit: (data: { username: string; password: string }) => void;
    loginError: string | null;
    errors: any;
}

const LoginTemplate: React.FC<LoginTemplateProps> = ({onSubmit, loginError, errors}) => {
        const [showNotification, setShowNotification] = useState(false);

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            setShowNotification(true);
            setTimeout(() => setShowNotification(false), 2000);
        });
    };
    return (
        <div className="login-page">
            {/*<div className="login-info">*/}
            {/*    <p onClick={() => handleCopy('bertram_g')}>Login: bertram_g</p>*/}
            {/*    <p onClick={() => handleCopy('qwe!@#QWE')}>PWD: qwe!@#QWE</p>*/}
            {/*</div>*/}
            <LoginForm onSubmit={onSubmit} errors={errors} />
            {loginError && <p className="login-error">{loginError}</p>}
            {showNotification && <div className="copy-notification">Скопировано!</div>}
        </div>
    );
};

export default LoginTemplate;
