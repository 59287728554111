import React, {useRef, useEffect, useState} from "react";
import {ReactComponent as Facingicon} from "./img/facingIcon.svg";
import {ReactComponent as PhotoIcon} from "./img/photoIcon.svg";
import {ReactComponent as DeleteIcon} from "./img/deleteIcon.svg";
import {ReactComponent as DownloadIcon} from "./img/downloadIcon.svg";

import "./css/webcam.cmp.css"
import Webcam from "react-webcam";
import axios from "axios";
import {API_REGISTRY_UPLOAD_PHOTO} from "../../../utils/service/api.endpoint";
import {bypassTemplateQuestionID, save_draft_answer} from "../../../pages/new/checklist.form";
import {BypassType} from "../view/view.bypass.checklist";
import {axiosInstance} from "../../../utils/service/axiosConfig";


function StartCamera({checklistID, answerID, setIsSidebarOpen, cameraPhoto, setCameraPhoto, saveMethod}) {
    const [error, setError] = useState();
    const [isEnabled, setEnabled] = useState(true);
    const [isMakedPhoto, setIsMakedPhoto] = useState(false);
    const [facing, setFacing] = useState("user");
    const videoRef = useRef(null);
    const streamRef = useRef(null);
    const canvasRef = useRef(null);

    const startStream = () => {
        navigator.mediaDevices
            .getUserMedia({
                audio: false,
                video: {
                    facingMode: facing,
                },
            })
            .then((stream) => {
                streamRef.current = stream;
                videoRef.current.srcObject = streamRef.current;
                videoRef.current.onloadedmetadata = () => videoRef.current.play();
            })
            .catch((err) => {
                setError(err.name);
            });
    };

    const stopStream = () => {
        if (streamRef.current) {
            streamRef.current.getTracks().forEach((track) => track.stop());
        }
    };

    const makePhoto = () => {
        const videoWidth = videoRef.current.scrollWidth;
        const videoHeight = videoRef.current.scrollHeight;
        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;
        if (facing === "user") {
            const context = canvasRef.current.getContext("2d");
            context.scale(-1, 1);
            context.drawImage(videoRef.current, 0, 0, -videoWidth, videoHeight);
            setIsMakedPhoto(true)
        } else {
            canvasRef.current
                .getContext("2d")
                .drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
            setIsMakedPhoto(true)
        }

    };

    const deletePhoto = () => {
        console.log("delete");
        const context = canvasRef.current.getContext("2d");
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        context.clearRect(0, 0, -canvasRef.current.width, canvasRef.current.height);
        setIsMakedPhoto(false)
    };

    const downloadPhoto = () => {
        const link = document.createElement("a");
        link.download = "photo.png";
        link.href = canvasRef.current.toDataURL("image/png");
        link.click();
    };

    const uploadPhoto = async () => {
        try {
            const canvas = canvasRef.current;
            const imageData = canvas.toDataURL("image/png");
            const formData = new FormData();
            formData.append("photo_file", convertBase64ToFile(imageData)); // Конвертируем данные изображения в файл
            formData.append("checklist_id", checklistID);
            formData.append("answer_id", answerID);
            formData.append("save_method", saveMethod)

            const response = await axiosInstance.post(API_REGISTRY_UPLOAD_PHOTO, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data.success) {


                setCameraPhoto(prevCameraPhoto => [...prevCameraPhoto, response.data.photo_path]);


                setEnabled(false);
                setIsSidebarOpen(false)


                console.log("Изображение успешно сохранено по пути:", response.data.photo_path);
            } else {
                console.error("Ошибка при сохранении изображения");
            }

        } catch (error) {
            console.error(error);
        }
    };

    const convertBase64ToFile = (base64String) => {
        const binaryString = window.atob(base64String.split(",")[1]);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return new File([bytes], "photo.png", {type: "image/png"});
    };


    useEffect(() => {
        setError(null);
        stopStream();
        if (isEnabled) startStream();
    }, [isEnabled, facing]);

    return (
        <>
            <video
                className={facing === "user" ? "mirror" : ""}
                playsInline
                muted
                autoPlay
                ref={videoRef}
            ></video>
            <canvas className="webcam-canvas" ref={canvasRef}></canvas>
            {error && <div className="error">{error}</div>}
            {isEnabled && <camh1>{facing === "user" ? "Front Cam" : "Back Cam"}</camh1>}
            <div className="controls">
                {/*<button onClick={() => setEnabled(!isEnabled)}>*/}
                {/*    {isEnabled ? "Off" : "ON"}*/}
                {/*</button>*/}
                <button className="controlsShot" onClick={() => makePhoto()} style={{minWidth:'70px', height:'70px'}}>
                    <PhotoIcon />
                </button>
                <button
                    onClick={() => setFacing(facing === "user" ? "environment" : "user")}
                >
                    <Facingicon/>
                </button>
                <button onClick={() => deletePhoto()}>
                    <DeleteIcon/>
                </button>

                {isMakedPhoto && <button onClick={() => uploadPhoto()}>
                    <DownloadIcon/>
                </button>}
            </div>
        </>
    );
}

export default StartCamera;