import React, {useState} from 'react';
import {Card} from '@consta/uikit/Card';
import {Button} from '@consta/uikit/Button';
import {Combobox} from '@consta/uikit/Combobox';
import {Grid, GridItem} from '@consta/uikit/Grid';
import {BYPASS_TEMPLATE_ID} from "../../utils/service/config";
import {NewTemplateDataType} from "../../types/new.type";


interface ChecklistSelectorProps {
    selectedTemplate: NewTemplateDataType | undefined;
    setSelectedTemplate: (selectedTemplate: NewTemplateDataType | undefined) => void;
    setIsSidebarOpen: (open: boolean) => void;
    templateData: NewTemplateDataType[];
}

const ChecklistSelector: React.FC<ChecklistSelectorProps> = ({
                                                                 setSelectedTemplate,
                                                                 setIsSidebarOpen,
                                                                 templateData,
                                                                 selectedTemplate,
                                                             }) => {
    const [showKB, setShowKB] = useState<boolean>(false);

    const handleBypass = () => {
        const bypassTemplate = templateData.find((item: NewTemplateDataType) => item.id === BYPASS_TEMPLATE_ID);
        if (bypassTemplate) {
            bypassTemplate.template_id = BYPASS_TEMPLATE_ID;
            setSelectedTemplate(bypassTemplate);
            setIsSidebarOpen(true);
        }
    };

    const handleSelectTemplate = (value: any) => {
        value.template_id = value.id;
        setSelectedTemplate(value);
        setIsSidebarOpen(!!value);
    };

    const getUniqueGroups = (checklists: any) => {
        const groups: string[] = [];
        checklists.forEach((checklist: any) => {
            if (!groups.includes(checklist.group)) {
                groups.push(checklist.group);
            }
        });
        return groups;
    };


    return (
        <React.Fragment>
            <Grid className="gridSlave" cols={1}>
                <GridItem col={1} style={{paddingLeft: '20px', paddingRight: '20px'}}>
                    <Card className='selector-card-container'>
                        <div className='selector-bypass-container' onClick={handleBypass}>
                            <div className='selector-bypass-right-block'>
                                <img src="/new_bypass.jpg" alt="Обходной чеклист"/>
                                <div className='selector-bypass-button'>
                                    ОБХОД
                                </div>
                            </div>
                        </div>
                    </Card>
                </GridItem>

                {/*{showKB && (*/}
                {/*    <GridItem col={1}>*/}
                {/*        <Card>*/}
                {/*            <Button*/}
                {/*                label="ПРОВЕРКА ПО КБ"*/}
                {/*                size="l"*/}
                {/*                onClick={() => setShowKB(!showKB)}*/}
                {/*            />*/}
                {/*            <Combobox*/}
                {/*                placeholder="Выберите шаблон"*/}
                {/*                items={templateData.filter((item) => item.id !== BYPASS_TEMPLATE_ID)}*/}
                {/*                groups={getUniqueGroups(templateData)}*/}
                {/*                size="m"*/}
                {/*                view="default"*/}
                {/*                form="default"*/}
                {/*                label="Новый чеклист:"*/}
                {/*                labelPosition="top"*/}
                {/*                value={selectedTemplate}*/}
                {/*                onChange={({value}) => handleSelectTemplate(value)}*/}
                {/*                getItemKey={(item: any) => item.id}*/}
                {/*                getItemLabel={(item: any) => item.name}*/}
                {/*                getItemGroupKey={(item) => item.group}*/}
                {/*                getGroupKey={(group: string) => group}*/}
                {/*                getGroupLabel={(group: string) => group}*/}
                {/*            />*/}
                {/*        </Card>*/}
                {/*    </GridItem>*/}
                {/*)}*/}
                {/*AT DEV - NOW HIDDEN*/}
                {/*<GridItem col={1}>*/}
                {/*    <Card className='card_pageNew'>*/}
                {/*        <Button className='button_pageNew' label="ПРОВЕРКА ПО КБ" size="l"*/}
                {/*                onClick={() => setShowKB(!showKB)}/>*/}
                {/*        {showKB &&*/}
                {/*            <Combobox*/}
                {/*                placeholder="Выберите шаблон"*/}
                {/*                items={templateData.filter(items => items.id !== bypassTemplateID)}*/}
                {/*                groups={getUniqueGroups(templateData)}*/}
                {/*                size="m"*/}
                {/*                view="default"*/}
                {/*                form="default"*/}
                {/*                label='Новый чеклист:'*/}
                {/*                labelPosition="top"*/}
                {/*                value={selectedTemplate}*/}
                {/*                onChange={({value}) => {*/}
                {/*                    handleSelectTemplate(value)*/}
                {/*                }}*/}
                {/*                getItemKey={(item) => item.name}*/}
                {/*                getItemLabel={(item) => item.name}*/}
                {/*                getItemGroupKey={(item) => item.group}*/}
                {/*                getGroupKey={(group: string) => group}*/}
                {/*                getGroupLabel={(group: string) => group}*/}
                {/*            />}*/}
                {/*    </Card>*/}
                {/*</GridItem>*/}
            </Grid>
        </React.Fragment>
    );
};

export default ChecklistSelector;
