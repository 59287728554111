import React, {useCallback, useMemo, useState, useEffect, useRef} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import moment from "moment";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {fetchChecklistData, fetchDeleteChecklist} from "../../utils/service/fetch.endpoint";

import '../../styles/grid.journal.css';

import {ModuleRegistry} from "@ag-grid-community/core";
import {ViewModeType} from "../../types/new.type";
import {useAuth} from "../../utils/wrapper/authContext";
import {
    columnDefsInspectorBypass,
    columnDefsInspectorKB,
    getRowStyle,
    rowStyle, statusPanel
} from "../../page/report/component/helpers/grid.report.settings";
import {JournalTemplateProps} from "../../templates/journal.template";

ModuleRegistry.registerModules([SetFilterModule]);

interface AgGridTableProps extends JournalTemplateProps {
    onOpenSidebar: (id: string, template: any, mode: ViewModeType) => void;

}

const AgGridTable: React.FC<AgGridTableProps> = ({onOpenSidebar, fetchType}) => {
    const gridRef = useRef<any>();
    const [rowData, setRowData] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const {user} = useAuth()

    const statusBar = useMemo(() => {
        return statusPanel
    }, []);

    const customEngLocale = {
        rowGroupColumnsEmptyMessage: 'Перетащите сюда, чтобы сгруппировать значения',
        pinColumn: 'Закрепить столбец',
        pinLeft: 'Закрепить слева',
        pinRight: 'Закрепить справа',
        noPin: 'Не закреплять',
        autosizeThisColumn: 'Автонастройка ширины',
        autosizeAllColumns: 'Автонастройка ширины всех колонок',
        groupBy: 'Группировать по',
        resetColumns: 'Сбросить настройки',
        ariaSearch: 'Поиск...',
        columnGroup: 'Группировка'
    };

    const gridOptions = {
        localeText: customEngLocale
    };

    const columnDefs = useMemo(() => [
        {field: "id", headerName: "id", hide: true},
        {
            field: "watch_date",
            headerName: "Дата",
            filter: true,
            valueFormatter: (params: any) => moment(params.value).format("DD.MM.YYYY")
        },
        {field: "watch_place", headerName: "КП", width: 100, filter: true, enableRowGroup: true},
        {field: "template", headerName: "Шаблон", width: 55, filter: true, enableRowGroup: true},
        {field: "author", headerName: "Составил", hide: true, filter: true, enableRowGroup: true},
        {
            field: "is_draft",
            headerName: "Черновик",
            filter: true,
            enableRowGroup: true,
            cellRenderer: (params: any) => (params.value ? "Да" : "Нет")
        }
    ], []);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        resizable: true,
        flex: 1,
        enablePivot: true
    }), []);

    const onGridReady = useCallback(async () => {

        setRowData(await fetchChecklistData(user?.userRole ?? '', {fetchType}))
        setIsLoading(false);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        onGridReady();
    }, [onGridReady]);

    const getContextMenuItems = useCallback((params: any) => {
        let result = [
            {
                name: "Посмотреть",
                action: async () => {
                    onOpenSidebar(params.node.data.id, {
                        id: params.node.data.id,
                        name: params.node.data.template,
                        template_id: params.node.data.template_id
                    }, 'view');
                },
                icon: '<img src="/icon/view1cm.png" alt="View"/>'
            },
            params.node.data.is_draft && {
                name: "Изменить",
                action: async () => {
                    onOpenSidebar(params.node.data.id, {
                        id: params.node.data.id,
                        name: params.node.data.template,
                        template_id: params.node.data.template_id
                    }, 'draft');
                },
                icon: '<img src="/icon/edit1cm.png" alt="Edit"/>'
            },
            "separator",
            {
                name: "Удалить",
                action: async () => {
                    const deleteStatus = await fetchDeleteChecklist({checklistID: params.node.data.id});
                    if (deleteStatus.status === 200) onGridReady();
                },
                icon: '<img src="/icon/delete1cm.png" alt="Delete"/>'
            }
        ];
        return result;
    }, [onOpenSidebar, onGridReady]);

    const getRowId = useMemo(() => (params: any) => params.data.id, []);

    return (
        <div className="journal-grid ag-theme-alpine gridSlave">
            {isLoading ? "LOADING" : (
                <AgGridReact
                    gridOptions={gridOptions}
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    getRowId={getRowId}
                    getContextMenuItems={getContextMenuItems}
                    statusBar={statusBar}
                    rowGroupPanelShow={'always'}
                />
            )}
        </div>
    );
};

export default AgGridTable;
