import React, {useRef, useState} from "react";
import {Header, HeaderModule} from "@consta/uikit/Header";
import {IconDinosaur} from "@consta/uikit/IconDinosaur";
import {useNavigate} from "react-router-dom";
import {IconExit} from "@consta/uikit/IconExit";
import HeaderMenuComponent from "../../molecules/header/headerMenuCmp.molecule.header";
import UserComponent from "../../atoms/header/userComponent.atom.header";
import LogoutContextMenuComponent from "../../molecules/header/logoutContextMenuCmp.molecule.header";
import {useAuth} from "../../utils/wrapper/authContext";
import '../../styles/header.css'

export type ItemLogout = {
    label: string;
    icon: any;
    event?: () => void;
};

const HeaderOrganism = (props: { countNew?: any }) => {
    const [isOpenLogout, setIsOpenLogout] = useState<boolean>(false);
    const ref = useRef(null);
    const nav = useNavigate();
    const {user} = useAuth()

    let menuItems: any[] = [];
    let itemsLogout: ItemLogout[] = [
        {
            label: 'Выйти',
            icon: IconExit,
            event: () => handleLogout(),
        },
    ];

    if (!user?.isContract) {
        itemsLogout.unshift({
            label: 'Dashboard обход',
            icon: IconDinosaur,
            event: () => handleMenuItemClick('/total_info_bypass'),
        });
    }

    if (user && user.userRole.toLowerCase() === 'инспектор') {
        menuItems.push({
            label: "НОВЫЕ",
            href: "new",
            active: window.location.pathname === '/new',
        });
    }

    if (!user?.isContract) {
        menuItems.push({
            label: "В РАБОТЕ",
            href: "journal",
            active: window.location.pathname === '/journal',
        });
                menuItems.push({
            label: "ЧЕРНОВИКИ",
            href: "draft-journal",
            active: window.location.pathname === '/draft-journal',
        });
    } else {
        menuItems.push({
            label: "Нарушения",
            href: "violante",
            active: window.location.pathname === '/violante',
        });
    }

    const handleMenuItemClick = (path: string) => {
        nav(path);
        setIsOpenLogout(false);
    };

    const handleLogout = () => {
        menuItems = [];
        localStorage.clear();
        nav('/');
        window.location.reload();
        setIsOpenLogout(false);
    };

    const getItemOnClickLogout = (item: ItemLogout) => item.event || (() => alert(item.label));

    const renderLeftSide = (item: ItemLogout): React.ReactNode => {
        const Icon = item.icon;
        return <Icon size="s"/>;
    };

    return (
        <Header
            leftSide={
                <HeaderModule className='header-style' indent="l">
                    <HeaderMenuComponent items={menuItems}/>
                </HeaderModule>
            }
            rightSide={
                <HeaderModule indent="s">
                    <UserComponent
                        name={user?.last_name ?? ''}
                        info={user?.user_company ?? ''}
                        onClick={() => setIsOpenLogout(!isOpenLogout)}
                        refProp={ref}
                    />
                    <LogoutContextMenuComponent
                        items={itemsLogout}
                        isOpen={isOpenLogout}
                        anchorRef={ref}
                        onClickOutside={() => setIsOpenLogout(false)}
                        getItemOnClick={getItemOnClickLogout}
                        renderLeftSide={renderLeftSide}
                    />
                </HeaderModule>
            }
        />
    );
};

export default HeaderOrganism;
