// преобразование данных для мапа MainInfo
import {MainInfoDictType} from "../../../../core.component/mainInfo.constructor";

export function transformManinInfoData(data: any, dtype: MainInfoDictType) {
    const result: any[] = []
    Object.keys(data.main_info).forEach((key: string, item) => {
        if (key === 'watch_date') {
            result.push({
                label: 'Дата осмотра',
                name: key,
                answer: data.main_info[key],
                group: null
            })
            return
        }
        if (dtype[key].type === 'dict') {
            result.push({
                label: dtype[key].label,
                name: key,
                answer: data.main_info[key].answer.name,
                group: data.main_info[key].answer.group
            })
            return
        } else {
            result.push({
                label: dtype[key].label,
                name: key,
                answer: data.main_info[key].answer,
                group: null
            })
            return
        }
    })
    return result
}
