import React, {useContext, useEffect, useState} from 'react'
import {Grid, GridItem} from "@consta/uikit/Grid";
import {useQuery} from "@tanstack/react-query";
import {fetchReportData, fetchResponsibleData} from "../../utils/service/fetch.endpoint";
import {Loader} from '@consta/uikit/Loader';
import AnswerCountCard from "./component/answer.card";
import GridReport from "../../page/report/component/grid.report";
import SnackBarComponent from "../../core.component/snackBar";
import PageReportBypass from "../../page/report/page.report.bypass";
import {Collapse} from '@consta/uikit/Collapse';
import {Badge} from "@consta/uikit/Badge";
import {FetchDataResponsibleInterface} from "./page.violante";
import {useAuth} from "../../utils/wrapper/authContext";


export interface AnswerInterface {
    answer_id: string;
    checklist_id: string;
    date: string;
    checklist: string;
    watch_place: string;
    watch_object: string;
    structural_subdivision: string;
    recipient: string;
    is_karkas: boolean;
    answer: string;
    number: string;
    photo: string[];
    is_decided: boolean;
    decided_answer: string;
    decided_date: Date;
    is_returned: boolean;
    returned_answer: string;
    decided_photo: string[];
    checklist_group: string
    checklist_name: string
}

export type viewTypeType = 'responsible_cabinet' | 'report'

const PageChecklist = () => {
    const [filterReportData, setFilterReportData] = useState<AnswerInterface[] | undefined>(undefined)
    const [gridData, setGridData] = useState<AnswerInterface[] | undefined>(undefined)
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [isOpenRegistry, setOpenRegistry] = useState<boolean>(false);
    const [isRefetchData, setIsRefetchData] = useState<boolean>(false);
    const {user} = useAuth()


    const {data: fetchData, refetch: refetchData, isLoading} = useQuery<FetchDataResponsibleInterface>({
        queryKey: ['fetchData'],
        queryFn: async (): Promise<FetchDataResponsibleInterface> => {
            const fetchResult = await fetchResponsibleData();
            return fetchResult;
        },
        enabled: !!user?.isContract
    });

    useEffect(() => {

    }, [filterReportData]);

    return (
        <React.Fragment>
            <Grid cols="2" style={{width: '90vw', margin: '15px'}} colGap="xs" rowGap="xs">
                <GridItem col={1}>
                    {isLoading ? <Loader size="s"/> :
                        fetchData &&
                        <AnswerCountCard dataAnswer={fetchData.karkas_data.assigned}
                                         otherAnswer={[]}
                                         cardType={"assigned"}
                                         setFilterReportData={setFilterReportData}/>
                    }
                </GridItem>
                <GridItem col={1}>
                    {isLoading ? <Loader size="s"/> :
                        fetchData &&
                        <AnswerCountCard dataAnswer={fetchData.bypass_data.decided}
                            // otherAnswer={fetchData.waiting_answer}
                                         cardType={"decided"}
                                         setFilterReportData={setFilterReportData}/>
                    }
                </GridItem>
                {filterReportData &&
                    <GridItem col={2}>
                        <GridReport filterData={filterReportData}
                                    viewType='responsible_cabinet'
                                    setShowSnackBar={setShowSnackBar}
                                    checklistTemplateType={'karkas'}/>
                    </GridItem>
                }

            </Grid>
            {showSnackBar && <SnackBarComponent setShowSnackBar={setShowSnackBar}
                                                messageText={'Отчет об устранении нарушения отправлен на проверку!'}/>}
        </React.Fragment>
    )
}

export default PageChecklist