import React, {createContext, useContext, useState, ReactNode, useCallback, useEffect} from 'react';
import {jwtDecode} from 'jwt-decode';
import {axiosInstance} from "../service/axiosConfig";
import {API_CHECK_AUTH_URL, API_TOKEN_REFRESH} from "../service/api.endpoint";

interface AuthContextType {
    accessToken: string | null;
    refreshToken: string | null;
    user: Token | null;
    login: (accessToken: string, refreshToken: string) => void;
    logout: (navigate: (path: string) => void) => void;
    refreshAccessToken: () => Promise<void>;
    updateUser: (updatedUser: Token) => void;
    fetchUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export interface Token {
    exp: number;
    username: string;
    userRole: string;
    last_name: string;
    first_name: string;
    isContract: string;
    user_company: string;

}

export function UpdateLocalStorage(decodedToken: Token, newAccessToken?: string, newRefreshToken?: string) {
    if (newAccessToken) {
        localStorage.setItem('accessToken', newAccessToken);
    }
    if (newRefreshToken) {
        localStorage.setItem('refreshToken', newRefreshToken);
    }

}

export const AuthProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [accessToken, setAccessToken] = useState<string | null>(localStorage.getItem('accessToken'));
    const [refreshToken, setRefreshToken] = useState<string | null>(localStorage.getItem('refreshToken'));
    const [user, setUser] = useState<Token | null>(() => {
        const token = localStorage.getItem('accessToken');
        return token ? jwtDecode<Token>(token) : null;
    });

    const login = (newAccessToken: string, newRefreshToken: string) => {
        setAccessToken(null);
        setRefreshToken(null);
        setUser(null);
        // console.log("Login: Clearing user state");
        setTimeout(() => {
            setAccessToken(newAccessToken);
            setRefreshToken(newRefreshToken);
            const decodedToken = jwtDecode<Token>(newAccessToken);
            setUser(decodedToken);
            // console.log("Login: Setting user to", decodedToken);
            UpdateLocalStorage(decodedToken, newAccessToken, newRefreshToken);
        }, 0);
    };


    const logout = (navigate: (path: string) => void) => {
        setAccessToken(null);
        setRefreshToken(null);
        setUser(null);
        localStorage.clear()
        navigate('/');
    };

    const refreshAccessToken = useCallback(async () => {
        if (!refreshToken) {
            logout(() => window.location.href = '/');
            return;
        }

        try {
            const response = await axiosInstance.post(API_TOKEN_REFRESH, {
                refresh: refreshToken,
            });

            if (response.status !== 200) {
                throw new Error('Failed to refresh token');
            }

            const data = response.data;
            const newAccessToken = data.access;
            const decodedToken = jwtDecode<Token>(newAccessToken);

            setAccessToken(newAccessToken);
            setUser(decodedToken);
            UpdateLocalStorage(decodedToken, newAccessToken, refreshToken);
            console.log('Refresh access token successfully');
        } catch (error) {
            console.error('Failed to refresh access token:', error);
            logout(() => window.location.href = '/');
        }
    }, [refreshToken, logout]);


    const updateUser = (updatedUser: Token) => {
        setUser(updatedUser);
        UpdateLocalStorage(updatedUser, accessToken ?? '', refreshToken ?? '');
    };

    const fetchUser = useCallback(async () => {
        try {
            const response = await axiosInstance.get(API_CHECK_AUTH_URL);
            const data = response.data;

            const updatedUser: Token = {
                exp: data.exp | 0,
                user_company: data.org,
                username: data.username,
                userRole: data.user_role,
                last_name: data.last_name,
                first_name: data.first_name,
                // user_id: data.id,
                isContract: data.is_contract
            };

            setUser(updatedUser);
            UpdateLocalStorage(updatedUser, accessToken ?? '', refreshToken ?? '');
        } catch (error) {
            console.error('Failed to fetch user data:', error);
        }
    }, [accessToken, refreshToken]);

    useEffect(() => {
        if (accessToken) {
            fetchUser();
            const decodedToken = jwtDecode<Token>(accessToken);
            setUser(decodedToken);
        }
    }, [accessToken, fetchUser]);

    return (
        <AuthContext.Provider
            value={{accessToken, refreshToken, user, login, logout, refreshAccessToken, updateUser, fetchUser}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth error');
    }
    return context;
};
