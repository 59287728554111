import React from 'react';
import HeaderOrganism from "./organisms/header/header.organism";

const HeaderLayout = () => {
    return (
            <HeaderOrganism />
    );
};

export default HeaderLayout;
