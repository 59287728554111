import React from "react";
import { Modal } from "@consta/uikit/Modal";
import TextAtom from "../../atoms/new/textAtom.new";
import ButtonAtom from "../../atoms/new/buttonAtom.new";

interface ModalOrganismProps {
    isOpen: boolean;
    onClose: () => void;
    onSaveDraft: () => void;
    onContinue: () => void;
    isMainInfoFilled: boolean;
}

const ModalOrganism: React.FC<ModalOrganismProps> = ({
    isOpen,
    onClose,
    onSaveDraft,
    onContinue,
    isMainInfoFilled,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            hasOverlay
            onClickOutside={onClose}
            onEsc={onClose}
        >
            <div style={{ padding: "10px" }}>
                {isMainInfoFilled ? (
                    <>
                        <TextAtom
                            as="p"
                            size="m"
                            view="secondary"
                            lineHeight="m"
                            style={false}
                            content="Вы ответили не на все вопросы!"
                        />
                        <TextAtom
                            as="p"
                            size="m"
                            view="secondary"
                            lineHeight="m"
                            style={false}
                            content="Сохранить текущее состояние как черновик?"
                        />
                    </>
                ) : (
                    <TextAtom
                        as="p"
                        size="m"
                        view="secondary"
                        lineHeight="m"
                        style={false}
                        content="Не заполнена основная информация!"
                    />
                )}
            </div>
            <div style={{ padding: "15px" }}>
                <ButtonAtom
                    size="m"
                    view="primary"
                    label="Продолжить"
                    width="default"
                    onClick={onContinue}
                    style={{ marginRight: "10px" }}
                />
                {isMainInfoFilled && (
                    <ButtonAtom
                        size="m"
                        view="primary"
                        label="Сохранить черновик"
                        width="default"
                        onClick={onSaveDraft}
                    />
                )}
            </div>
        </Modal>
    );
};

export default ModalOrganism;
