import React, {useContext, useEffect, useState} from 'react'
import {Grid, GridItem} from "@consta/uikit/Grid";
import {useQuery} from "@tanstack/react-query";
import {fetchReportData, fetchResponsibleData} from "../../utils/service/fetch.endpoint";
import {Loader} from '@consta/uikit/Loader';
import AnswerCountCard from "./component/answer.card";
import GridReport from "../../page/report/component/grid.report";
import SnackBarComponent from "../../core.component/snackBar";
import PageReportBypass from "../../page/report/page.report.bypass";
import {Collapse} from '@consta/uikit/Collapse';
import {Badge} from "@consta/uikit/Badge";
import {useAuth} from "../../utils/wrapper/authContext";

export interface FetchDataResponsibleInterface {
    bypass_data: {
        assigned: AnswerInterface[];
        waiting: AnswerInterface[];
        returned: AnswerInterface[];
        decided: AnswerInterface[];
    },
    karkas_data: {
        assigned: AnswerInterface[];
    }
}

export interface AnswerInterface {
    answer_id: string;
    checklist_id: string;
    date: string;
    checklist: string;
    watch_place: string;
    watch_object: string;
    structural_subdivision: string;
    recipient: string;
    is_karkas: boolean;
    answer: string;
    number: string;
    photo: string[];
    thumbnail: string[];
    is_decided: boolean;
    decided_answer: string;
    decided_date: Date;
    is_returned: boolean;
    returned_answer: string;
    decided_photo: string[];
    checklist_group: string
    checklist_name: string
}

export type viewTypeType = 'responsible_cabinet' | 'report'

const PageViolante = () => {
    const [filterReportData, setFilterReportData] = useState<AnswerInterface[] | undefined>(undefined)
    const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
    const [isOpenRegistry, setOpenRegistry] = useState<boolean>(false);
    const [isRefetchData, setIsRefetchData] = useState<boolean>(false);
    const {user} = useAuth()


    const {data: fetchData, refetch: refetchData, isLoading} = useQuery<FetchDataResponsibleInterface>({
        queryKey: ['fetchData'],
        queryFn: async (): Promise<FetchDataResponsibleInterface> => {
            const fetchResult = await fetchResponsibleData();
            return fetchResult;
        },
        enabled: !!user?.isContract,
    });

    const {data: reportData, refetch: refetchReportData} = useQuery({
        queryKey: ['reportData'],
        queryFn: async (): Promise<any> => {
            return await fetchReportData({checklistTemplate: 'bypass'})
        },
        enabled: !!user?.isContract,
    });

    useEffect(() => {
        refetchData()
        // refetchReportData()
        setFilterReportData(undefined)
    }, [showSnackBar, isRefetchData]);

    return (
        <React.Fragment>
            <Grid className='gridSlave' cols="2" colGap="xs" rowGap="xs">
                <GridItem col="2">
                    {reportData?.length > 0 &&
                        <Collapse label={<Badge status="error" label="ЕСТЬ НЕ РАСПРЕДЕЛЕННЫЕ НАРУШЕНИЯ" size="s"/>}
                                  isOpen={isOpenRegistry}
                                  onClick={() => setOpenRegistry(!isOpenRegistry)}>
                            <PageReportBypass isRefetchData={isRefetchData}
                                              setIsRefetchData={setIsRefetchData}/>
                        </Collapse>
                    }
                </GridItem>
                <GridItem col={1}>
                    {isLoading ? <Loader size="s"/> :
                        fetchData &&
                        <AnswerCountCard dataAnswer={fetchData.bypass_data.assigned}
                                         otherAnswer={fetchData.bypass_data.returned}
                                         cardType={"assigned"}
                                         setFilterReportData={setFilterReportData}/>
                    }
                </GridItem>
                <GridItem col={1}>
                    {isLoading ? <Loader size="s"/> :
                        fetchData &&
                        <AnswerCountCard dataAnswer={fetchData.bypass_data.decided}
                            // otherAnswer={fetchData.waiting_answer}
                                         cardType={"decided"}
                                         setFilterReportData={setFilterReportData}/>
                    }
                </GridItem>
                {filterReportData &&
                    <GridItem className='grid-table-violante' col={2}>
                        <GridReport filterData={filterReportData}
                                    viewType='responsible_cabinet'
                                    setShowSnackBar={setShowSnackBar}
                                    checklistTemplateType={'bypass'}/>
                    </GridItem>
                }

            </Grid>
            {showSnackBar && <SnackBarComponent setShowSnackBar={setShowSnackBar}
                                                messageText={'Отчет об устранении нарушения отправлен на проверку!'}/>}
        </React.Fragment>
    )
}

export default PageViolante