import {BuildSaveData} from "../../page/new/component/helpers/buildSaveData";
import {CheckedNullCountAnswer} from "../../page/new/component/helpers/checkedNullCountAnswer";
import {fetchUpdateChecklist} from "../service/fetch.endpoint";


export const handleSaveButton = async (
    props: any,
    valueAnswer: any,
    valueDate: Date | null,
    valueMainInfo: any,
    mainInfoData: any,
    currentTemplate: any,
    savedChecklistData: any,
    newTemplateData: any,
    dispatch: (action: any) => void, // Теперь используем dispatch
    username: string | undefined
) => {
    const nullCount = CheckedNullCountAnswer(valueAnswer);

    if (nullCount > 0 || !valueDate || !valueMainInfo?.[0] || !valueMainInfo?.[1]) {
        dispatch({type: 'TOGGLE_MODAL'}); // Управляем состоянием через dispatch
    } else if (valueMainInfo) {
        const mainInfo = mainInfoData;
        const checklistID = props.checklistID;
        if (props.viewMode !== 'new' && currentTemplate?.template_id != null) {
            currentTemplate.id = currentTemplate?.template_id;
        }
        const saveData = BuildSaveData({
            mainInfo,
            valueAnswer,
            valueMainInfo,
            checklistID,
            currentTemplate,
            savedChecklistData,
            newTemplateData,
            valueDate,
            username
        });

        const saveResult = await fetchUpdateChecklist({
            saveData: saveData,
            isDraft: saveData.answer.length === 0
        });

        if (saveResult?.status === 200) {
            props.isSavedSuccess(true);
        }
    }
}
