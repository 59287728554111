import React, {useEffect, useState} from "react";
import {fetchDeleteChecklist} from "../../utils/service/fetch.endpoint";
import {Sidebar} from "@consta/uikit/Sidebar";
import ChecklistView from "../../pages/new/checklist.form";
import ButtonGroup from "../../molecules/journal/buttonGroupMolecule.journal";
import {ViewModeType} from "../../types/new.type";


interface SidebarViewProps {
    isSidebarOpen: boolean;
    setIsSidebarOpen: (open: boolean) => void;
    checklistID: string;
    viewMode: ViewModeType;
    selectedTemplate?: any;
    setSelectedTemplate?: any;
    setSavedResult?: any;
}

const SidebarView: React.FC<SidebarViewProps> = (props) => {
    const [savePressed, setSavePressed] = useState<boolean>(false);
    const [isSavedSuccess, setIsSavedSuccess] = useState<boolean>(false);

    const handleCloseSideBar = () => {
        props.viewMode === 'new' && props.setSelectedTemplate(null);
        props.setIsSidebarOpen(false);
    };

    const handleCancelButton = (checklistID: string) => {
        props.viewMode === 'new' && fetchDeleteChecklist({checklistID: checklistID});
        handleCloseSideBar();
    };

    useEffect(() => {
        if (isSavedSuccess) {
            props.viewMode === 'new' && props.setSavedResult(isSavedSuccess);
            handleCloseSideBar();
        }
    }, [isSavedSuccess]);

    return (
        <Sidebar
            isOpen={props.isSidebarOpen}
            onClickOutside={() => handleCloseSideBar()}
            onEsc={() => handleCloseSideBar()}
            position="bottom"
            size="full"
        >
            <Sidebar.Content>
                {props.selectedTemplate && (
                    <ChecklistView
                        checklistID={props.checklistID}
                        newTemplateData={props.selectedTemplate}
                        viewMode={props.viewMode}
                        isSaveButtonPressed={savePressed}
                        isSavedSuccess={setIsSavedSuccess}
                        currentTemplate={props?.selectedTemplate}
                        setIsSidebarOpen={props.setIsSidebarOpen}
                        setSavePressed={setSavePressed}
                    />
                )}
            </Sidebar.Content>
            <Sidebar.Actions>
                <ButtonGroup
                    checklistID={props.checklistID}
                    viewMode={props.viewMode}
                    setSavePressed={setSavePressed}
                    handleCancelButton={handleCancelButton}
                />
            </Sidebar.Actions>
        </Sidebar>
    );
};

export default SidebarView;
