import React from "react";
import {GridItem} from "@consta/uikit/Grid";
import {Combobox} from "@consta/uikit/Combobox";
import {TextField} from "@consta/uikit/TextField";
import {ComboboxType} from "../../../core.component/mainInfo.constructor";
import {MainInfoProps} from "../../../molecules/new/mainInfoMolecule.new";

const RenderMainInfo: React.FC<MainInfoProps> = (props) => {
    const handleChange = (value: any, index: number) => {
        if (props.valueMainInfo) {
            const updatedValueMainInfo = [...props.valueMainInfo];
            updatedValueMainInfo[index] = value;
            props.setValueMainInfo(updatedValueMainInfo);
        }
    }

    return props.mainInfoData.map(
        (el: ComboboxType, index: number) => {
            const comboboxData =
                props.viewMode !== 'view'
                    ? props.questionData?.main_info[el.result_name]?.data
                    : el.data

            const comboboxGroups =
                props.viewMode !== 'view'
                    ? props.questionData?.main_info[el.result_name]?.group
                    : el.group

            return (
                <React.Fragment key={`MainInfoData${index}`}>
                    <GridItem col={6} key={`mainInfoData-${index}`}
                              style={{paddingTop: '10px'}}>
                        {el.type === 'dict' ?
                            <Combobox
                                className='grid-item'
                                placeholder={el.label}
                                items={comboboxData || []}
                                groups={comboboxGroups || []}
                                size="m"
                                view="default"
                                form="default"
                                labelPosition="top"
                                status={!props.valueMainInfo[index] ? 'alert' : 'success'}
                                value={props.valueMainInfo && props.valueMainInfo[index]}
                                onChange={({value}) => handleChange(value, index)}
                                getItemKey={(item: any) => item.name}
                                getItemLabel={(item: any) => item.name}
                                getItemGroupKey={(item) => item.group}
                                getGroupKey={(group: string) => group}
                                getGroupLabel={(group: string) => group}
                            /> : <TextField style={{width: '95%'}}
                                            onChange={({value}) => handleChange(value, index)}
                                            value={props.valueMainInfo && props.valueMainInfo[index]}
                                            type="text"
                                            placeholder={el.label}
                            />}
                    </GridItem>
                </React.Fragment>
            )
        }
    )
}

export default RenderMainInfo