import {SnackBar, SnackBarItemShowProgressProp, SnackBarItemStatus} from "@consta/uikit/SnackBar";
import React, {useEffect, useReducer} from "react";

interface SnackBarInterface {
    setShowSnackBar: any
    alertType?: 'alert' | 'normal'
    messageText: string
}

export type ItemType = {
    key: number;
    message: string;
    status: SnackBarItemStatus;
    progressMode?: 'line' | 'timer';
};

export function reducer(
    state: ItemType[],
    action: { type: 'add' | 'remove'; item: ItemType },
): ItemType[] {
    switch (action.type) {
        case 'add':
            return [...state, action.item];
        case 'remove':
            return state.filter((itemInState) => itemInState.key !== action.item.key);
    }
}

export const getItemShowProgress = (item: ItemType) => item.progressMode;

const SnackBarComponent = ({setShowSnackBar, messageText}: SnackBarInterface) => {
    const [items, dispatchItems] = useReducer<React.Reducer<
        ItemType[],
        { type: 'add' | 'remove'; item: ItemType; key?: number | string }
    >
    >(reducer, []);

    const generateHandleAdd =
        (status: SnackBarItemStatus, progressMode?: SnackBarItemShowProgressProp) =>
            () => {
                const key = items.length + 1;
                const item: ItemType = {
                    key,
                    message: `${messageText}`,
                    status,
                    progressMode,
                };
                dispatchItems({type: 'add', item});
            };

    const handleAlertAdd = generateHandleAdd('alert', 'timer');
    const handleNormalAdd = generateHandleAdd('normal', 'timer');
    const handleLineNormalAdd = generateHandleAdd('normal', 'line');
    const handleHiddenTimerAdd = generateHandleAdd('normal');

    useEffect(() => handleNormalAdd(), []);

    return (
        <React.Fragment>
            <SnackBar
                style={{position: 'absolute', bottom: 0, zIndex: 1000}}
                items={items}
                onItemClose={() => setShowSnackBar(false)}
                getItemShowProgress={getItemShowProgress}
                getItemAutoClose={() => 2}
            />
        </React.Fragment>
    )
}

export default SnackBarComponent