import React from 'react';
import SidebarView from "../organisms/new/sidebarViewOrganism.new";
import {NewTemplateDataType, ViewModeType} from "../types/new.type";


interface ChecklistTemplateProps {
    isSidebarOpen: boolean;
    setIsSidebarOpen: (open: boolean) => void;
    checklistID: string;
    viewMode: ViewModeType;
    selectedTemplate?: any;
    setSelectedTemplate?: any;
    setSavedResult?: any;
    templateData: NewTemplateDataType[];
    questionData?: any;
    valueAnswer?: any;
    setValueAnswer?: any;
    valueMainInfo?: any;
}

const ChecklistTemplate: React.FC<ChecklistTemplateProps> = (props) => {
    return (
        <div>
            <SidebarView {...props} />
        </div>
    );
};

export default ChecklistTemplate;
