import React from 'react';
import {Grid, GridItem} from "@consta/uikit/Grid";
import {Card} from "@consta/uikit/Card";
import TextAtom from "../../atoms/new/textAtom.new";
import DatePickerAtom from "../../atoms/new/datePickerAtom.new";
import RenderMainInfo from "../../page/new/view/view.mainInfo.checklist";


export interface MainInfoProps {
    mainInfoData: any;
    viewMode: string;
    questionData: any;
    valueMainInfo: any;
    setValueMainInfo: (value: any) => void;
    valueDate: Date | null;
    setValueDate: (date: Date | null) => void;
    currentTemplateName?: string;
}

const MainInfo: React.FC<MainInfoProps> = (props) => (
    <Grid cols="6">
        <GridItem className="gridSlave" col={6}>
            <Card className='card-view'>
                <Grid cols={6}>
                    <GridItem col={6}>
                        <TextAtom content={props.currentTemplateName}/>
                    </GridItem>
                    <GridItem col={6}>
                        <DatePickerAtom
                            placeholder='Дата осмотра'
                            value={props.valueDate}
                            onChange={props.setValueDate}
                        />
                    </GridItem>
                    {props.mainInfoData && props.valueMainInfo && Array.isArray(props.mainInfoData) &&
                        RenderMainInfo(props)
                    }
                </Grid>
            </Card>
        </GridItem>
    </Grid>
);

export default MainInfo;
