// set background colour on every row
import moment from "moment";

export const rowStyle = {background: '#eaeaea'};

// set background colour on even rows again, this looks bad, should be using CSS classes
export const getRowStyle = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
        return {background: 'white'};
    }
};

export const columnDefsInspectorBypass = [
    {
        field: 'id',
        headerName: 'id',
        hide: true
    },
    {
        field: 'date',
        headerName: 'Дата',
        width: 115,
        autoHeight: true,
        filter: true,
        tooltipField: "date",
        enableRowGroup: true,
        // wrapText: true
    },
    {
        field: 'watch_place',
        headerName: 'Куст',
        // width: 100,
        filter: true,
        enableRowGroup: true,
    },
    {
        field: 'watch_object',
        headerName: 'Обьект',
        // width: 100,
        filter: true,
        enableRowGroup: true,
    },
    {
        field: 'is_karkas',
        headerName: 'КБ',
        // width: 100,
        filter: true,
        hide: true,
        enableRowGroup: true,
        // valueFormatter: (params: any) => params.value ? 'ДА' : 'НЕТ'
    },
]

export const columnDefsInspectorKB = [
    {
        field: 'id',
        headerName: 'id',
        hide: true
    },
    {
        field: 'date',
        headerName: 'Дата',
        width: 115,
        autoHeight: true,
        filter: true,
        tooltipField: "date",
        enableRowGroup: true,
        // wrapText: true
    },
    {
        field: 'checklist_name',
        headerName: 'Чеклист',
        // width: 100,
        autoHeight: true,
        filter: true,
        wrapText: true,
        enableRowGroup: true,
    },
    {
        field: 'count',
        headerName: '!',
        // width: 100,
        filter: true,
        enableRowGroup: true,
    },
]

export function gridContext() {
    let result = [];
    result.push(
        {
            name: 'Посмотреть',
            action: async () => {
                console.log('watch')
            },
            icon: '<img src="/icon/view1cm.png" alt="View"/>'
        },
        {
            name: 'Изменить',
            action: async () => {
                console.log('1')
            },
            icon: '<img src="/icon/edit1cm.png" alt="Edit"/>'
        },
        {
            name: 'Отправить',
            action: async () => {
            },
            icon: '<img src="/icon/send1cm.png" alt="Send"/>'
        },
        // 'copy',
        'separator',
        // 'chartRange',
        {
            name: 'Удалить',
            action: async () => {
                console.log('3')
            },
            icon: '<img src="/icon/delete1cm.png" alt="Delete"/>'
        },
    )

    return result;
}

export const statusPanel = {
    statusPanels: [
        {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
    ],
};

export const colDef = {
    // width: 120,
    sortable: true,
    resizable: true,
    flex: 1,
};