// old cam func
import {Sidebar} from "@consta/uikit/Sidebar";
import {Button} from "@consta/uikit/Button";
import React, {useEffect, useState} from "react";
import {Grid, GridItem} from "@consta/uikit/Grid";
import StartCamera from "./camera.cmp";
import {SaveMethodType} from "./photo.collapse.cmp";

interface SidebarViewProps {
    isSidebarOpen: boolean;
    setIsSidebarOpen: (open: boolean) => void;
    checklistID: string;
    answerID: string;
    cameraPhoto: any;
    setCameraPhoto: any;
    saveMethod: SaveMethodType
}

const CameraSidebarView: React.FC<SidebarViewProps> = (props) => {
    const [savePressed, setSavePressed] = useState<boolean>(false)
    const [isSavedSuccess, setIsSavedSuccess] = useState<boolean>(false)
    const handleCloseSideBar = () => {
        props.setIsSidebarOpen(false)
    }

    useEffect(() => {
        if (isSavedSuccess) {
            handleCloseSideBar()
        }
    }, [isSavedSuccess]);

    return (
        <Sidebar
            isOpen={props.isSidebarOpen}
            onClickOutside={() => handleCloseSideBar()}
            onEsc={() => handleCloseSideBar()}
            position='bottom'
            size='full'
        >
            <Sidebar.Content>
                <div style={{height: '100%', width: '100%', backgroundColor: "black"}}>
                    <StartCamera checklistID={props.checklistID}
                                 answerID={props.answerID}
                                 setIsSidebarOpen={props.setIsSidebarOpen}
                                 cameraPhoto={props.cameraPhoto}
                                 setCameraPhoto={props.setCameraPhoto}
                                 saveMethod={props.saveMethod}/>
                </div>

            </Sidebar.Content>
            <Sidebar.Actions>
                <Grid cols={2}>
                    <GridItem col={1}>
                        <Button
                            size="m"
                            view="ghost"
                            label="Закрыть"
                            width="full"
                            onClick={() => handleCloseSideBar()}
                        />
                    </GridItem>

                    <GridItem col={1}>
                        <Button
                            size="m"
                            view="ghost"
                            label="Сохранить"
                            width="full"
                            onClick={() => setSavePressed(true)}
                        />
                    </GridItem>
                </Grid>
            </Sidebar.Actions>
        </Sidebar>
    );
};

export default CameraSidebarView;