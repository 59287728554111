import {NewTemplateDataType} from "../../types/new.type";
import {MainInfoDict} from "../../core.component/mainInfo.constructor";

interface PropsInterface {
    mainInfo: any
    valueAnswer: any
    valueMainInfo: any
    checklistID: string
    currentTemplate: any
    savedChecklistData?: any
    newTemplateData?: NewTemplateDataType
    valueDate: Date | null
    username?: string
}

export const BuildSaveData = (props: PropsInterface) => {
    //  формирование словаря для сохраниния в БД
    let mainInfoAnswer = {}
    let osmotrPlaceID: {} | null = null
    let valueMainInfoTemp: any


    valueMainInfoTemp = props.valueMainInfo

    props.mainInfo &&
    props.mainInfo.map((el: any, index: number) => {
        if (el.result_name === 'watch_place') {
            osmotrPlaceID = valueMainInfoTemp[index]
        }

        return mainInfoAnswer = {
            ...mainInfoAnswer,
            [el.result_name]: {id: MainInfoDict[el.result_name].id, answer: valueMainInfoTemp[index]},
        };
    })

    const saveData = {
        create_type: "complete",
        checklist_id: props.checklistID,
        current_template: props.currentTemplate,
        checklist_data: {
            username: props.username,
            template_id: props.savedChecklistData?.id || props.newTemplateData?.id,
            watch_date: props.valueDate,
            is_draft: false,
            is_trash: false,
            watch_place: osmotrPlaceID
        },
        main_info: mainInfoAnswer,
        answer: props.valueAnswer
    }
    return saveData
}