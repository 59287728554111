import React from "react";
import {User} from '@consta/uikit/User';

const UserComponent = ({name, info, onClick, refProp}: {
    name: string,
    info: string,
    onClick: () => void,
    refProp: any
}) => {
    return (
        <User
            size='s'
            name={name}
            info={info}
            status="available"
            onClick={onClick}
            withArrow={true}
            ref={refProp}
            style={{cursor: 'pointer'}}
        />
    );
};

export default UserComponent;
