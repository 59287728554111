import React from 'react';

import '../../styles/login.css'
import InputField from "../../atoms/login/inputField.atom";

interface FormFieldProps {
    id: string;
    label: string;
    type: string;
    placeholder?: string;
    error?: string;
    register: any;
    value?: string;
}

const FormField: React.FC<FormFieldProps> = ({id, label, type, placeholder, register, error, value}) => {
    return (
        <div className="login-form-field">
            <label htmlFor={id}>{label}</label>
            <InputField id={id} type={type} placeholder={placeholder} register={register} error={error} value={value}/>
        </div>
    );
};

export default FormField;
