import React, {useEffect, useState} from "react";
import {Collapse} from "@consta/uikit/Collapse";
import {Button} from "@consta/uikit/Button";
import Carousel from "./carousel.checklist.cmp";
import {IconCamera} from "@consta/uikit/IconCamera";
import {API_REGISTRY_UPLOAD_PHOTO} from "../../../utils/service/api.endpoint";
import {fetchDeletePhoto} from "../../../utils/service/fetch.endpoint";
import CameraSidebarView from "./camera.sidebar.cmp";
import {axiosInstance} from "../../../utils/service/axiosConfig";
import LoadingOverlay from "../../../organisms/loadingOverlay";


export type SaveMethodType = 'new' | 'decided'

const PhotoCollapse: React.FC<{
    index: number;
    checklistID: string;
    answerID: string;
    photo: string[];
    thumbnail?: string[];
    saveMethod: SaveMethodType;
    canDelete?: boolean;
    onUpdatePhotos?: (index: number, photo: string[], thumbnail: string[]) => void;
    viewMode?: string;
}> = ({index, checklistID, answerID, photo, thumbnail, saveMethod, canDelete, onUpdatePhotos, viewMode}) => {

    const [isCameraOpen, setIsCameraOpen] = useState<boolean>(false);
    const [cameraPhoto, setCameraPhoto] = useState(photo ? photo : []);
    const [thumbnailPhoto, setThumbnailPhoto] = useState(thumbnail ? thumbnail : []);
    const [isOpen, setIsOpen] = useState<boolean>(cameraPhoto.length > 0);
    const [isUploading, setIsUploading] = useState(false);  // Состояние для управления загрузкой
    const [uploadProgress, setUploadProgress] = useState(0); // Состояние для отслеживания прогресса загрузки

    useEffect(() => {
        cameraPhoto.length > 0 && setIsOpen(true);
    }, [cameraPhoto]);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                setIsUploading(true); // Начало загрузки
                const formData = new FormData();
                formData.append("photo_file", file);
                formData.append("checklist_id", checklistID);
                formData.append("answer_id", answerID);
                formData.append("save_method", saveMethod);

                const response = await axiosInstance.post(API_REGISTRY_UPLOAD_PHOTO, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: progressEvent => {
                        if (progressEvent.total) {  // Добавляем проверку на undefined
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setUploadProgress(percentCompleted); // Обновление прогресса
                        }
                    }
                });

                if (response.data.success) {
                    setCameraPhoto(prevCameraPhoto => [...prevCameraPhoto, response.data.photo_path]);
                    setThumbnailPhoto(prevThumbnailPhoto => [...prevThumbnailPhoto, response.data.thumbnail]);
                } else {
                    console.error("Ошибка при сохранении изображения:", response.data);
                }
            } catch (error) {
                console.error("Ошибка при отправке запроса:", error);
            } finally {
                setIsUploading(false); // Конец загрузки
                setUploadProgress(0); // Сброс прогресса
            }
        }
    };


    const handleDeletePhoto = async (photoPath: string) => {
        const uuidMatch = photoPath.match(/[0-9a-fA-F-]{36}/);
        const photoID = uuidMatch ? uuidMatch[0] : 'null';

        try {
            const result = await fetchDeletePhoto({photoID});

            if (result.status === 200) {
                // Удаляем фото из cameraPhoto и обновляем state
                setCameraPhoto((prevCameraPhoto) =>
                    prevCameraPhoto.filter((photo) => photo !== photoPath)
                );

                // Удаляем фото из thumbnailPhoto и обновляем state
                setThumbnailPhoto((prevThumbnailPhoto) =>
                    prevThumbnailPhoto.filter((thumbnail) => thumbnail !== photoPath)
                );

                // Вызываем onUpdatePhotos, если он определен
                if (onUpdatePhotos) {
                    onUpdatePhotos(index, cameraPhoto, thumbnailPhoto);
                }
            }
        } catch (error) {
            console.error('Ошибка при удалении фото:', error);
        }
    };


    return (
        <>
            <Collapse
                label="Фото"
                isOpen={isOpen}
                onClick={() => setIsOpen((prev: any) => !prev)}
                rightSide={
                    <React.Fragment>
                        {viewMode !== 'view' && <Button
                            iconLeft={IconCamera}
                            size="s"
                            view="ghost"
                            onClick={() => document.getElementById(`fileInput-${index}`)?.click()}
                        />}
                        <input
                            id={`fileInput-${index}`}
                            type="file"
                            accept="image/*"
                            style={{display: 'none'}}
                            onChange={handleFileChange}
                        />
                    </React.Fragment>
                }>
                <section>
                    <Carousel photo={cameraPhoto} thumbnail={thumbnailPhoto} onDeletePhoto={handleDeletePhoto}
                              canDelete={canDelete}/>
                </section>
            </Collapse>
            <CameraSidebarView
                isSidebarOpen={isCameraOpen}
                setIsSidebarOpen={setIsCameraOpen}
                checklistID={checklistID}
                answerID={answerID}
                cameraPhoto={cameraPhoto}
                setCameraPhoto={setCameraPhoto}
                saveMethod={saveMethod}
            />
            {isUploading && <LoadingOverlay progress={uploadProgress}/>} {/* Отображение прогресса загрузки */}
        </>
    )
}

export default PhotoCollapse;
