import React, {useState} from 'react';
import SidebarView from "../organisms/journal/sideBarViewOrganism.journal";
import AgGridTable from "../organisms/journal/agGridTableOrhanism.journal";
import {ViewModeType} from "../types/new.type";


export interface JournalTemplateProps {
    fetchType: 'at_work' | 'draft'
}

const JournalTemplate: React.FC<JournalTemplateProps> = ({fetchType}) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [checklistID, setChecklistID] = useState<string>('');
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
    const [viewMode, setViewMode] = useState<ViewModeType>('view');

    const handleOpenSidebar = (id: string, template: any, mode: ViewModeType) => {
        setChecklistID(id);
        setSelectedTemplate(template);
        setViewMode(mode);
        setIsSidebarOpen(true);
    };

    return (
        <>
            <SidebarView
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                checklistID={checklistID}
                viewMode={viewMode}
                selectedTemplate={selectedTemplate}
            />
            <AgGridTable onOpenSidebar={handleOpenSidebar} fetchType={fetchType}/>
        </>
    );
};

export default JournalTemplate;
