import {Grid, GridItem} from "@consta/uikit/Grid";
import {Text} from '@consta/uikit/Text';
import "./css/answerDetail.css"
import React, {useEffect, useState} from "react";
import Carousel from "../../new/component/carousel.checklist.cmp";
import {
    fetchAnswerAssign,
    fetchDeleteDecidedPhoto,
    fetchReportData, fetchSavedChecklist
} from "../../../utils/service/fetch.endpoint";
import {AnswerInterface, viewTypeType} from "../../../pages/responsible/page.violante";
import {Badge} from '@consta/uikit/Badge';
import PhotoCollapse from "../../new/component/photo.collapse.cmp";
import {Card} from "@consta/uikit/Card";
import {TextField} from "@consta/uikit/TextField";
import {Divider} from "@mui/material";

import {useQuery} from "@tanstack/react-query";
import {MainInfoType, TemplateDataType} from "../../new/page.new";
import {KarkasType} from "../../new/view/view.karkas.checklist";
import {MainInfoDict, MainInfoDictType} from "../../../core.component/mainInfo.constructor";
import {RadioGroup} from "@consta/uikit/RadioGroup";
import CommentCollapse from "../../new/component/comment.collapse.cmp";
import {transformManinInfoData} from "./helpers/transform.mainInfo.data";
import {Button} from "@consta/uikit/Button";

type MainInfoAnswerDetailType = {
    label: string,
    name: string,
    answer: string | Date,
    group: string | null
}
type ViewDataType = {
    mainInfo: MainInfoAnswerDetailType[],
    violationInfo: KarkasType[]
}

interface AnswerDetailInterface {
    answerData: AnswerInterface
    setShowSidebarAnswerDetail: (showSideBar: boolean) => void
    setShowSnackBar: (showSnackBar: boolean) => void
    viewType: viewTypeType
    isDecided?: boolean
    setIsDecided?: (isDecided: boolean) => void
    setToSaveDecidedComment?: (toSaveDecideComment: string | null) => void
    setToSaveReturnComment?: (toSaveReturnComment: string | null) => void
}

const AnswerDetailKB = (props: AnswerDetailInterface) => {
    const [dtype] = useState(MainInfoDict)
    const [viewData, setViewData] = useState<ViewDataType | null>(null)
    const [liquidate, setLiquidate] = useState<boolean>(false)
    const [valueAnswer, setValueAnswer] = useState<Array<any>>([]);

    const [decideComment, setDecideComment] = useState<string | null>(null);
    const [returnComment, setReturnComment] = useState<string | null>(null);
    const [clickReturn, setClickReturn] = useState<boolean>(false)


    useEffect(() => {
        if (!dtype) return
        const result: ViewDataType = {
            mainInfo: [],
            violationInfo: []
        }

        const fetchData = async (): Promise<TemplateDataType> => {
            const answerDetailKBData = await fetchSavedChecklist({checklistId: props.answerData.checklist_id})
            // берем только нарушения
            answerDetailKBData.question = answerDetailKBData.question.filter((el: KarkasType) => el.answer === "НЕТ")
            return answerDetailKBData
        }

        fetchData().then((data: any) => {
            // преобразование данных для мапа MainInfo
            result.mainInfo = transformManinInfoData(data, dtype)
            result.violationInfo = data.question
            setViewData(result)
        })
    }, [props.answerData.checklist_id]);

    const MainInfoRender = () => {
        const watchDate: MainInfoAnswerDetailType | undefined = viewData?.mainInfo.find(el => el.name === 'watch_date');
        const viewDataElement = viewData?.mainInfo.filter(el => el.name !== 'watch_date');
        return (
            <div style={{padding: '15px', marginBottom: '15px'}}>
                <Text view="secondary" style={{marginBottom: '15px'}}>
                    Дата осмотра: {watchDate?.answer.toString()}
                </Text>
                {viewDataElement?.map((el, index) => (
                    el.group === null ? (
                        <React.Fragment key={index}>
                            <Text>{el.label}:
                                <span style={{fontWeight: 900}}>
                                    {el.answer.toString()}
                                </span>
                            </Text>
                        </React.Fragment>
                    ) : (
                        <React.Fragment key={index}>
                            {/*<Text view="secondary">{el.group}</Text>*/}
                            <Text>{el.label}:
                                <span style={{fontWeight: 900}}>
                                    {el.group} {el.name === 'watch_place' ? `КП${el.answer.toString()}` : el.answer.toString()}
                                </span>
                            </Text>
                        </React.Fragment>
                    )
                ))}
            </div>
        )
    }

    const ViolationInfoRender = () => {
        // {
        //   "id": "94aaf146-87c5-4c23-a4ad-79ec26e97a49",
        //   "number": "1.2",
        //   "category": "ТОП-2",
        //   "question": "Наличие у водителя документов соответствующих категорий и допусков на право управления транспортным средством и спецтехникой.",
        //   "points": 6,
        //   "measures": "Отстранение персонала/информацию в ЦИТС",
        //   "chapter": "1.Требование к организации поездки",
        //   "answer": "НЕТ",
        //   "comment": null,
        //   "recipient": null,
        //   "watch_object": null,
        //   "is_karkas": null,
        //   "photo": []
        // },
        return (

            <div style={{padding: '15px'}}>
                {viewData?.violationInfo.map((violation: KarkasType, index: number) => (
                    <React.Fragment key={index}>
                        <Grid cols='4'>
                            {/*Заголовок раздела чек листа*/}
                            {
                                index === 0 ?
                                    <GridItem col={4} style={{}}>
                                        <Text lineHeight="2xs" view='primary' weight='bold'
                                              size='xl'>{violation?.chapter}</Text>
                                    </GridItem> :
                                    viewData?.violationInfo && viewData?.violationInfo[index - 1]?.chapter !== violation?.chapter &&
                                    <GridItem col={4} style={{}}>
                                        <Text lineHeight="2xs" view='primary' weight='bold'
                                              size='xl'>{violation?.chapter}</Text>
                                    </GridItem>
                            }
                            {/*Вопросы*/}

                            <GridItem col={2} style={{backgroundColor: 'lightgrey'}}>
                                <Text size='xs' weight='bold' align='center'>{violation?.category}</Text>
                            </GridItem>
                            <GridItem col={2} style={{backgroundColor: 'lightgrey'}}>
                                <Text size='xs' weight='bold' align='center'>БАЛЛЫ-{violation?.points}</Text>
                            </GridItem>
                            <GridItem col={4}>
                                <Text lineHeight="2xs" align="left" view="primary">{violation?.question}</Text>
                            </GridItem>
                            <GridItem col={4} style={{backgroundColor: '#f3f3f3'}}>
                                <Text weight='bold' lineHeight="2xs" align="left">Меры реагирования</Text>
                            </GridItem>
                            <GridItem col={4}>
                                <Text lineHeight="2xs" align="left">{violation?.measures}</Text>
                            </GridItem>

                            {violation?.comment &&
                                <React.Fragment>
                                    <GridItem col={4} style={{backgroundColor: '#f3f3f3'}}>
                                        <Text weight='bold' lineHeight="2xs" align="left">Примечание:</Text>
                                    </GridItem>
                                    <GridItem col={4}>
                                        <Text>{violation?.comment}</Text>
                                    </GridItem>
                                </React.Fragment>

                            }
                            <GridItem colStart={!liquidate ? 4 : 1} col={!liquidate ? 1 : 4}>
                                <Button size={'xs'} style={{width: '100%'}} label='УСТРАНИТЬ'
                                        onClick={() => setLiquidate(!liquidate)}/>
                                <CommentCollapse index={index} valueComment={valueAnswer}
                                                 setValueComment={setValueAnswer}/>
                            </GridItem>

                            <GridItem col={3} style={{height: '30px'}}></GridItem>

                        </Grid>

                    </React.Fragment>
                ))}
            </div>
        )
    }

    return (
        <React.Fragment>
            {viewData ?
                <div>
                    <div className={'header_main_div'}>
                        <Text className={'answer_detail_header'} align={'center'} size="xl" transform="uppercase"
                              weight="bold">
                            {props.answerData.checklist_name}
                        </Text>
                    </div>
                    <MainInfoRender/>
                    <ViolationInfoRender/>
                </div>
                : <p>LOADING</p>
            }
        </React.Fragment>
    )
}
export default AnswerDetailKB