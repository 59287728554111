import {Grid, GridItem} from "@consta/uikit/Grid";
import {Text} from '@consta/uikit/Text';
import "./css/answerDetail.css"

import React, {useEffect, useState} from "react";
import Carousel from "../../new/component/carousel.checklist.cmp";
import {fetchAnswerAssign, fetchDeleteDecidedPhoto} from "../../../utils/service/fetch.endpoint";
import {AnswerInterface, viewTypeType} from "../../../pages/responsible/page.violante";
import {Badge} from '@consta/uikit/Badge';
import PhotoCollapse from "../../new/component/photo.collapse.cmp";
import {Card} from "@consta/uikit/Card";
import {TextField} from "@consta/uikit/TextField";
import {Divider} from "@mui/material";
import {useAuth} from "../../../utils/wrapper/authContext";

interface AnswerDetailInterface {
    answerData: AnswerInterface
    setShowSidebarAnswerDetail: (showSideBar: boolean) => void
    setShowSnackBar: (showSnackBar: boolean) => void
    viewType: viewTypeType
    isDecided?: boolean
    setIsDecided?: (isDecided: boolean) => void
    setToSaveDecidedComment?: (toSaveDecideComment: string | null) => void
    setToSaveReturnComment?: (toSaveReturnComment: string | null) => void
}

const AnswerDetailBypass = (props: AnswerDetailInterface) => {
    const [decideComment, setDecideComment] = useState<string | null>(null);
    const [returnComment, setReturnComment] = useState<string | null>(null);
    const [clickReturn, setClickReturn] = useState<boolean>(false)
    const {user} = useAuth()

    const handleHeaderAssignClick = () => {
        props.setShowSidebarAnswerDetail(false)
        props.setShowSnackBar(true)


        fetchAnswerAssign({answerID: props.answerData.answer_id})
        // window.location.reload()
    }

    const handleDecideClick = () => {
        props.setIsDecided &&
        props.setIsDecided(true)
    }

    const handleReturnClick = () => {
        setClickReturn(true)
        props.setIsDecided &&
        props.setIsDecided(true)
    }

    const handleCancelDecideClick = async () => {
        if (!props.setIsDecided) {
            return;
        }
        await fetchDeleteDecidedPhoto({answerID: props.answerData.answer_id})
        props.setIsDecided(false)
        setClickReturn(false)
    }

    const handleChangeDecidedComment = ({value}: { value: string | null }) => {
        if (!props.setToSaveDecidedComment) {
            return
        }
        setDecideComment(value)
        props.setToSaveDecidedComment(value)
    }

    const handleChangeReturnComment = ({value}: { value: string | null }) => {
        if (!props.setToSaveReturnComment) {
            return
        }
        setReturnComment(value)
        props.setToSaveReturnComment(value)
    }

    useEffect(() => {
        console.log(props.answerData)
    }, [props.answerData]);

    // useEffect(() => {
    //     if (props.isDecided && !props.answerData.decided_date && props.answerData.is_returned) {
    //         setDecideComment(props.answerData.decided_answer)
    //     }
    // }, [props.isDecided]);

    return (
        <React.Fragment>
            <div className={'header_main_div'}>
                <Text className={'answer_detail_header'} size="xl" transform="uppercase" weight="bold">
                    Кустовая площадка №{props.answerData.watch_place}
                </Text>
                {user?.isContract && props.viewType === 'report' &&
                    <Badge status="success" label="Взять в работу" style={{marginRight: '10px', cursor: 'pointer'}}
                           className='answer_icon_add_header'
                           onClick={handleHeaderAssignClick}/>}
                {props.viewType === 'responsible_cabinet' && !props.isDecided && !props.answerData.decided_date &&
                    <Badge status="success" label="Устранить" style={{marginRight: '10px', cursor: 'pointer'}}
                           className='answer_icon_add_header'
                           onClick={handleDecideClick}/>}
                {props.isDecided &&
                    <Badge status="error" label="отменить" style={{marginRight: '10px', cursor: 'pointer'}}
                           className='answer_icon_add_header'
                           onClick={handleCancelDecideClick}/>}
                {!clickReturn && !user?.isContract && props.viewType === 'report' && props.answerData.is_decided &&
                    <Badge status="error" label="отклонить" style={{marginRight: '10px', cursor: 'pointer'}}
                           className='answer_icon_add_header'
                           onClick={handleReturnClick}/>}
            </div>
            <Grid cols={3}>
                <GridItem col={3}>
                    <Text className={'answer_detail_h1'} size="3xl" view="brand" weight="bold">
                        {props.answerData.watch_object} №{props.answerData.number}</Text>
                </GridItem>
                <GridItem col={3}>
                    <Text className={'answer_detail_date'} size="s" weight="bold">
                        {props.answerData.date}
                    </Text>
                    <Text className={'answer_detail_answer'}>
                        {props.answerData.answer}
                    </Text>
                </GridItem>
                <GridItem col={3}>
                    <Text className={'answer_detail_recipient'} weight="bold">
                        Ответственный: {props.answerData.recipient}
                    </Text>
                </GridItem>
                <GridItem col={3}>
                    {props.answerData.photo.length > 0 ? <div className={'answer_detail_photo'}>
                            <Carousel photo={props.answerData.photo} thumbnail={props.answerData.thumbnail} canDelete={false}/></div> :
                        <Text className={'answer_detail_no_photo'} weight="bold">
                            Фотоматериала нет
                        </Text>
                    }
                </GridItem>

                {/*при просмотре не принятого нарушения*/}
                {!props.answerData.decided_date && props.answerData.is_returned &&
                    <GridItem col={3}>
                        <Card style={{margin: '15px', padding: '5px'}}>
                            {!props.isDecided ?
                                <React.Fragment>
                                    <Text className={'answer_detail_h1'} size="3xl" view="brand" weight="bold">
                                        Отчет об устранении</Text>
                                    {
                                        props.answerData?.decided_answer.split('\n').map((line, index) => (
                                            <div style={{marginLeft: '15px'}} key={index}>{line}</div>
                                        ))
                                    }

                                    {props.answerData.photo.length > 0 ? <div className={'answer_detail_photo'}>
                                            <Carousel photo={props.answerData.decided_photo} thumbnail={props.answerData.decided_photo} canDelete={false}/></div> :
                                        <Text className={'answer_detail_no_photo'} weight="bold">
                                            Фотоматериала нет
                                        </Text>
                                    }
                                    <Divider/>
                                    <Text className={'answer_detail_h1'} size="xl" view="alert" weight="bold">
                                        Не принят по причине:</Text>
                                    {
                                        props.answerData?.returned_answer.split('\n').map((line, index) => (
                                            <div style={{marginLeft: '15px'}} key={index}>{line}</div>
                                        ))
                                    }
                                </React.Fragment> :
                                <React.Fragment>
                                    <Text className={'answer_detail_h1'} size="3xl" view="brand" weight="bold">
                                        Отчет об устранении</Text>
                                    <TextField value={decideComment}
                                               onChange={handleChangeDecidedComment}
                                               style={{width: '100%', marginBottom: "15px"}}
                                               type="textarea"
                                               placeholder="Выполненные мероприятия"
                                               labelPosition="top" rows={5}/>
                                    <PhotoCollapse index={1}

                                                   checklistID={props.answerData.checklist_id}
                                                   answerID={props.answerData.answer_id}
                                                   photo={props.answerData.decided_photo}
                                                   thumbnail={props.answerData.decided_photo}
                                                   saveMethod={'decided'}
                                                   canDelete={true}/>
                                    <Divider/>
                                    <Text className={'answer_detail_h1'} size="xl" view="alert" weight="bold">
                                        Не принят по причине:</Text>
                                    {
                                        props.answerData?.returned_answer.split('\n').map((line, index) => (
                                            <div style={{marginLeft: '15px'}} key={index}>{line}</div>
                                        ))
                                    }

                                </React.Fragment>
                            }
                        </Card>
                    </GridItem>
                }

                {/*при просмотре устраненного нарушения отправленного на проверку*/}
                {props.answerData.decided_date &&
                    <GridItem col={3}>
                        <Card style={{margin: '15px', padding: '5px'}}>
                            <Text className={'answer_detail_h1'} size="3xl" view="brand" weight="bold">
                                Отчет об устранении</Text>
                            {
                                props.answerData?.decided_answer.split('\n').map((line, index) => (
                                    <div style={{marginLeft: '15px'}} key={index}>{line}</div>
                                ))

                            }

                            {props.answerData.decided_photo.length > 0 ?
                                <div className={'answer_detail_photo'}>
                                    <Carousel photo={props.answerData.decided_photo} thumbnail={props.answerData.decided_photo}/>
                                </div> :
                                <Text className={'answer_detail_no_photo'} weight="bold">
                                    Фотоматериала нет
                                </Text>
                            }
                            <Divider/>
                            {props.answerData.returned_answer &&
                                <React.Fragment>
                                    <Text className={'answer_detail_h1'} size="xl" view="alert" weight="bold">
                                        Замечания:</Text>
                                    {
                                        props.answerData?.returned_answer.split('\n').map((line, index) => (
                                            <div style={{marginLeft: '15px'}} key={index}>{line}</div>
                                        ))
                                    }
                                </React.Fragment>
                            }

                        </Card>
                    </GridItem>
                }

                {/*при нажатии на badge устранить*/}
                {!clickReturn && props.isDecided && !props.answerData.is_returned &&
                    <GridItem col={3}>
                        <Card style={{margin: '15px', padding: '5px'}}>
                            <Text className={'answer_detail_h1'} size="3xl" view="brand" weight="bold">
                                Отчет об устранении</Text>
                            <TextField value={decideComment}
                                       onChange={handleChangeDecidedComment}
                                       style={{width: '100%', marginBottom: "15px"}}
                                       type="textarea"
                                       placeholder="Выполненные мероприятия"
                                       labelPosition="top" rows={5}/>
                            <PhotoCollapse index={1}

                                           checklistID={props.answerData.checklist_id}
                                           answerID={props.answerData.answer_id}
                                           photo={[]}
                                           saveMethod={'decided'}
                                           canDelete={true}/>
                        </Card>
                    </GridItem>
                }

                {/*при нажатии на badge отклонить*/}
                {clickReturn &&
                    <GridItem col={3}>
                        <Card style={{margin: '15px', padding: '5px'}}>
                            <Text className={'answer_detail_h1'} size="3xl" view="brand" weight="bold">
                                Причина отклонения:</Text>
                            <TextField value={returnComment}
                                       onChange={handleChangeReturnComment}
                                       style={{width: '100%', marginBottom: "15px"}}
                                       type="textarea"
                                       placeholder="Причина"
                                       labelPosition="top" rows={5}/>
                        </Card>
                    </GridItem>
                }
            </Grid>

        </React.Fragment>
    )
}
export default AnswerDetailBypass