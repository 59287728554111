export const mapQuestionToValueAnswer = (q: any) => ({
    id: q.id,
    answer: q.answer,
    comment: q.comment,
    number: q.number,
    recipient: q.recipient,
    watch_object: q.watch_object,
    is_karkas: q.is_karkas,
    photo: q.photo,
    thumbnail: q.thumbnail
});
