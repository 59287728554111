import React, {useContext, useEffect, useState} from 'react';
import ChecklistTemplate from "../../templates/new.template";
import {useQuery} from "@tanstack/react-query";
import {axiosInstance} from "../../utils/service/axiosConfig";
import {API_DICT_TEMPLATE_GET} from "../../utils/service/api.endpoint";
import {TemplateDataType} from "../../page/new/page.new";

// @ts-ignore
import {v4 as uuidv4} from 'uuid';
import ChecklistSelector from "../../molecules/new/checklistSelectorMolecule.new";
import {NewTemplateDataType} from "../../types/new.type";
import SnackBarComponent from "../../core.component/snackBar";

const NewChecklistPage: React.FC = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
    const [valueTemplate, setValueTemplate] = useState<NewTemplateDataType[]>([]);
    const [newChecklistID] = useState<string>(uuidv4());


    const [saveSuccess, setSaveSuccess] = useState<boolean>(false);

    // get template
    const {isPending, error, data, refetch} = useQuery({
        queryKey: ['template_list'],
        queryFn: () =>
            axiosInstance.get(API_DICT_TEMPLATE_GET).then(
                (res): { data: TemplateDataType[] } => {
                    setValueTemplate(res.data)
                    return res.data
                }
            ),
    })

    return (
        <div>
            {valueTemplate && (
                <ChecklistSelector
                    setIsSidebarOpen={setIsSidebarOpen}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    templateData={valueTemplate}
                />
            )}
            <ChecklistTemplate
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                checklistID={newChecklistID}
                viewMode="new"
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                templateData={valueTemplate}
                setSavedResult={setSaveSuccess}

            />
            {saveSuccess && (
                <SnackBarComponent
                    setShowSnackBar={setSaveSuccess}
                    messageText="Данные сохранены!"
                />
            )}
        </div>
    );
};

export default NewChecklistPage;
