import React, {useReducer, useEffect, useCallback} from "react";
import {useQuery} from "@tanstack/react-query";
import {fetchNewChecklist, fetchSavedChecklist, fetchUpdateChecklist} from "../../utils/service/fetch.endpoint";
import ModalOrganism from "../../organisms/new/saveModalOrganism.new";
import MainInfo from "../../molecules/new/mainInfoMolecule.new";
import QuestionGenerator from "../../organisms/new/questionGenerator.new";
import {PropsInterface} from "../../types/new.type";
import {BuildSaveData} from "../../utils/helpers/buildSaveData";
import {mainInfoDataCheck} from "../../utils/helpers/mainInfoDataCheck";
import {getMainInfoDataFromSavedData} from "../../utils/helpers/getMainInfoDataFromSavedData";
import {getShouldSaveDraft} from "../../utils/helpers/getShouldSaveDraft";
import {handleSaveButton} from "../../utils/helpers/handleSaveButton";
import {mapQuestionToValueAnswer} from "../../utils/helpers/mapQuestionToValueAnswer";
import {BYPASS_TEMPLATE_ID, MainInfoDict} from "../../utils/service/config";
// @ts-ignore
import {v4 as uuidv4} from "uuid";
import moment from "moment";
import {createNewChecklist} from "../../utils/helpers/createNewChecklist";
import {useAuth} from "../../utils/wrapper/authContext";

// Определяем начальное состояние и редуктор
const initialState = {
    valueDate: null,
    questionData: null,
    isNullCountModalOpen: false,
    valueMainInfo: [],
    valueAnswer: [],
    mainInfoData: null
};

function reducer(state: any, action: any) {
    console.log("Action received in reducer:", action);
    switch (action.type) {
        case 'SET_VALUE_DATE':
            return {...state, valueDate: action.payload};
        case 'SET_QUESTION_DATA':
            return {...state, questionData: action.payload};
        case 'SET_VALUE_MAIN_INFO':
            return {...state, valueMainInfo: action.payload};
        case 'SET_VALUE_ANSWER':
            return {...state, valueAnswer: Array.isArray(action.payload) ? action.payload : []};
        case 'SET_MAIN_INFO_DATA':
            return {...state, mainInfoData: action.payload};
        case 'TOGGLE_MODAL':
            return {...state, isNullCountModalOpen: !state.isNullCountModalOpen};
        default:
            throw new Error();
    }
}

const ChecklistForm = (props: PropsInterface) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        newTemplateData,
        viewMode,
        checklistID,
        currentTemplate,
        isSaveButtonPressed,
        isSaveDraftButtonPressed,
        setIsSidebarOpen,
        setSavePressed
    } = props;

    const {user} = useAuth()

    // Запрос для получения данных нового чеклиста
    const {data: newChecklistData} = useQuery({
        queryKey: ['newChecklist'],
        queryFn: async () => {
            const response = await fetchNewChecklist({templateId: newTemplateData.id});
            dispatch({type: 'SET_QUESTION_DATA', payload: response});

            createNewChecklist(props, savedChecklistData, newTemplateData, user?.username).then(() => {
                if (newTemplateData?.template_id !== BYPASS_TEMPLATE_ID) {
                    const temp = response.question.map((q: any) => ({
                        answer_id: uuidv4(),
                        id: q.id,
                        answer: null,
                        comment: null,
                        number: "unused",
                        recipient: "unused",
                        watch_object: "unused",
                        is_karkas: false
                    }));
                    dispatch({type: 'SET_VALUE_ANSWER', payload: temp});
                }
            })
            return response;
        },
        enabled: viewMode === 'new',
        refetchOnWindowFocus: false,
    });

    // Запрос для получения сохранённого чеклиста
    const {data: savedChecklistData} = useQuery({
        queryKey: ['savedChecklist'],
        queryFn: async () => {
            const dictData = await fetchNewChecklist({templateId: BYPASS_TEMPLATE_ID});
            const response = await fetchSavedChecklist({checklistId: checklistID});
            dispatch({type: 'SET_VALUE_MAIN_INFO', payload: response.mainInfo});
            dispatch({type: 'SET_QUESTION_DATA', payload: dictData});
            return response;
        },
        enabled: viewMode !== 'new' && checklistID !== undefined
    });

    // Функция для сохранения черновика
    const handleSaveDraft = () => {
        const mainInfo = buildMainInfoData(state.valueMainInfo);
        const saveData = BuildSaveData({
            mainInfo,
            valueAnswer: state.valueAnswer,
            valueMainInfo: state.valueMainInfo,
            checklistID,
            currentTemplate,
            savedChecklistData,
            newTemplateData,
            valueDate: state.valueDate
        });
        fetchUpdateChecklist({saveData, isDraft: true});
        dispatch({type: 'TOGGLE_MODAL'});
        setIsSidebarOpen(false);
    };

    // Функция для обработки изменений и сохранения с задержкой
    const debounceChangeValueAnswer = useCallback((valueAnswer: [], valueMainInfo: any, valueDate: any) => {
        const mainInfo = buildMainInfoData(valueMainInfo);
        const saveData = BuildSaveData({
            mainInfo,
            valueAnswer,
            valueMainInfo,
            checklistID,
            currentTemplate,
            savedChecklistData,
            newTemplateData,
            valueDate
        });
        fetchUpdateChecklist({saveData, isDraft: true});
    }, [checklistID, currentTemplate, savedChecklistData, newTemplateData]);

    const handleDebouncedSave = useCallback((valueAnswer: [], valueMainInfo: any, valueDate: any) => {
        if (state.valueDate !== valueDate) {
            debounceChangeValueAnswer(valueAnswer, valueMainInfo, valueDate);
        }
    }, [debounceChangeValueAnswer, state.valueDate]);

    // Эффект для обработки перед закрытием окна
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            const confirmationMessage = 'Изменения могут не сохраниться. Продолжить?';
            event.returnValue = confirmationMessage;
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // Эффект для обработки сохранения черновика
    useEffect(() => {
        const shouldSaveDraft = getShouldSaveDraft(props, newTemplateData, state.valueAnswer, state.valueDate, state.valueMainInfo);
        if (shouldSaveDraft) {
            handleDebouncedSave(state.valueAnswer, state.valueMainInfo, state.valueDate);
        }
    }, [state.valueAnswer, state.valueMainInfo, state.valueDate, newTemplateData, handleDebouncedSave]);

    // Эффект для обработки нажатия кнопки сохранения
    useEffect(() => {
        if (isSaveButtonPressed) {
            handleSaveButton(
                props,
                state.valueAnswer,
                state.valueDate,
                state.valueMainInfo,
                state.mainInfoData,
                currentTemplate,
                savedChecklistData,
                newTemplateData,
                dispatch,
                user?.username
            ).finally(() => {
                setSavePressed(false); // Сброс состояния
            });

        }
    }, [isSaveButtonPressed]);

    useEffect(() => {
        isSaveDraftButtonPressed &&
        handleSaveDraft()
    }, [isSaveDraftButtonPressed]);

    // Эффект для построения данных основной информации
    useEffect(() => {
        buildMainInfoData(state.valueMainInfo);
    }, [newTemplateData, savedChecklistData]);

    // Эффект для установки значений вопросов в зависимости от роли пользователя
    useEffect(() => {
        if (viewMode !== 'new' && savedChecklistData?.question) {
            const {main_info, question} = savedChecklistData;
            const mainInfoKeys = Object.keys(main_info).filter(key => key !== 'watch_date');
            const mainInfoValues = mainInfoKeys.map(key => main_info[key].answer);
            dispatch({type: 'SET_VALUE_MAIN_INFO', payload: mainInfoValues});

            if (user && user?.userRole.toLowerCase() === 'инспектор') {
                dispatch({type: 'SET_VALUE_ANSWER', payload: question.map(mapQuestionToValueAnswer)});
            } else if (user?.userRole.toLowerCase() === 'ответственный') {
                const negativeAnswers = question.filter((q: any) => q.answer === 'НЕТ');
                dispatch({type: 'SET_VALUE_ANSWER', payload: negativeAnswers.map(mapQuestionToValueAnswer)});
            }
        }
    }, [state.questionData, savedChecklistData, state.mainInfoData, user?.userRole]);

    // Построение данных основной информации
    const buildMainInfoData = (valueMainInfo: any) => {
        if (viewMode === 'new' && newTemplateData) {
            const {main_info} = newTemplateData;
            if (main_info) {
                const mainInfoKeys = Object.keys(main_info) as (keyof typeof main_info)[];
                const result = mainInfoKeys.filter(key => main_info[key])
                    .map(key => MainInfoDict[key]);
                dispatch({type: 'SET_MAIN_INFO_DATA', payload: result});
                if (!valueMainInfo) {
                    dispatch({type: 'SET_VALUE_MAIN_INFO', payload: result.map(() => null)});
                }
                return result;
            }
        } else if (viewMode !== 'new' && savedChecklistData) {
            const {main_info} = savedChecklistData;
            dispatch({
                type: 'SET_VALUE_DATE',
                payload: new Date(moment(main_info.watch_date).format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
            });
            const result = getMainInfoDataFromSavedData(Object.keys(MainInfoDict), main_info);
            dispatch({type: 'SET_MAIN_INFO_DATA', payload: result});
            return result;
        }
    };

    return (
        <>
            <MainInfo
                mainInfoData={state.mainInfoData}
                viewMode={viewMode}
                questionData={state.questionData}
                valueMainInfo={state.valueMainInfo}
                setValueMainInfo={(data) => dispatch({type: 'SET_VALUE_MAIN_INFO', payload: data})}
                valueDate={state.valueDate}
                setValueDate={(date) => dispatch({type: 'SET_VALUE_DATE', payload: date})}
                currentTemplateName={currentTemplate?.name}
            />

            <QuestionGenerator
                currentTemplate={currentTemplate}
                questionData={state.questionData}
                valueAnswer={state.valueAnswer}
                setValueAnswer={(data) => {
                    dispatch({type: 'SET_VALUE_ANSWER', payload: data});
                }}
                newChecklistID={checklistID}
                viewMode={viewMode}
                valueMainInfo={state.valueMainInfo}
            />

            <ModalOrganism
                isOpen={state.isNullCountModalOpen}
                onClose={() => dispatch({type: 'TOGGLE_MODAL'})}
                onSaveDraft={handleSaveDraft}
                onContinue={() => dispatch({type: 'TOGGLE_MODAL'})}
                isMainInfoFilled={mainInfoDataCheck(state.valueDate, state.valueMainInfo)}
            />
        </>
    );
};

export default ChecklistForm;
